export const n1GoiEnglish = [
  ["あいそう", "愛想", "Amiable"],
  ["あいだがら", "間柄", "relationship"],
  ["あいつぐ", "相次ぐ", "successive"],
  ["あいま", "合間", "Between"],
  ["あえて", "敢えて", "dare"],
  ["あおぐ", "仰ぐ", "Look up"],
  ["あか", "垢", "Dirt"],
  ["あかし", "証", "proof"],
  ["あかじ", "赤字", "deficit"],
  ["あかす", "明かす", "Reveal"],
  ["あからむ", "赤らむ", "Redness"],
  ["あがり", "上がり／〜上がり", "Rising/Rising"],
  ["あきらめ", "諦め", "Give up"],
  ["あく", "悪", "evil"],
  ["アクセル", "accelerator", "accelerator"],
  ["あくどい", "あくどい", "Cruel"],
  ["あご", "顎", "jaw"],
  ["あこがれ", "憧れ", "longing"],
  ["あさ", "麻", "hemp"],
  ["あざ", "痣", "Bruise"],
  ["あさましい", "浅ましい", "Shameful"],
  ["あざむく", "欺く", "throw dust in eyes"],
  ["あざやか", "鮮やか", "vivid"],
  ["あざわらう", "嘲笑う", "ridicule"],
  ["あしからず", "悪しからず", "Sorry."],
  ["あじわい", "味わい", "Taste"],
  ["あせる", "焦る", "panic"],
  ["あせる", "褪せる「色が〜」", "The color fades..."],
  ["あたい", "値", "value"],
  ["あたいする", "値する", "Deserves"],
  ["あたり", "当り／〜当り", "Hit/Hit"],
  ["あっか", "悪化", "Worsening"],
  ["あつかい", "扱い", "Treatment"],
  ["あっけない", "呆気ない", "How unexpected"],
  ["あっさり", "あっさり", "Light"],
  ["あっせん", "斡旋", "mediation"],
  ["あっとう", "圧倒", "Overwhelmed"],
  ["あっぱく", "圧迫", "Compression"],
  ["アップ", "up", "up"],
  ["あつらえる", "誂る", "Custom made"],
  ["あつりょく", "圧力", "pressure"],
  ["あて", "〜宛", "To"],
  ["あて", "当て", "Hit"],
  ["あてじ,当字、宛字", "Atoji", " Address"],
  ["あてる", "宛てる", "Address"],
  ["あとつぎ", "跡継", "Successor"],
  ["あとまわし", "後回し", "procrastinate"],
  ["あぶらえ", "油絵", "oil"],
  ["アプローチ", "approach", "approach"],
  ["あべこべ", "あべこべ", "Upside down"],
  ["あまえる", "甘える", "Being spoiled"],
  ["あまぐ", "雨具", "rain gear"],
  ["あまくち", "甘口", "Sweet"],
  ["アマチュア", "amateur", "amateur"],
  ["あみ", "網", "network"],
  ["あやつる", "操る", "manipulate"],
  ["あやぶむ", "危ぶむ", "Fear"],
  ["あやふや", "あやふや", "Vague"],
  ["あやまち", "過ち", "Mistake"],
  ["あやまる", "誤る", "err"],
  ["あゆみ", "歩み", "History"],
  ["あゆむ", "歩む", "walk"],
  ["あらかじめ", "予め", "In advance"],
  ["あらす", "荒らす", "Vandalize"],
  ["あらそい", "争い", "Dispute"],
  ["あらたまる", "改まる", "Change"],
  ["あらっぽい", "荒っぽい", "rough"],
  ["アラブ", "Arab", "Arab"],
  ["あられ", "霰", "hail"],
  ["ありさま", "有様", "Status"],
  ["ありのまま", "有りの儘", "As it is"],
  ["ありふれる", "有触れる", "Touchable"],
  ["アルカリ", "alkali", "Architectural"],
  ["アルミ", "aluminum", "aluminum"],
  ["あわす", "合わす", "Combine"],
  ["あわせ", "〜合わせ", "~ Match"],
  ["アンケート", "enquête", "Enquête"],
  ["アンコール", "encore", "Encore"],
  ["あんさつ", "暗殺", "assassination"],
  ["あんざん", "暗算", "Mental arithmetic"],
  ["あんじ", "暗示", "Suggestion"],
  ["あんじる", "案じる", "Worry"],
  ["あんせい", "安静", "rest"],
  ["あんのじょう", "案の定", "As expected"],
  ["い", "異", "Different"],
  ["い", "意", "Intention"],
  ["いいかげん", "好い加減", "Seriously"],
  ["いいわけ", "言い訳", "excuse"],
  ["いいん", "医院", "Clinic"],
  ["いえで", "家出", "Running away from home"],
  ["いかす", "生かす", "Make use of"],
  ["いかに", "いかに", "How"],
  ["いかにも", "いかにも", "Indeed"],
  ["いかり", "怒り", "anger"],
  ["いき", "粋", "Cool"],
  ["いぎ", "異議", "Objection"],
  ["いきがい", "生甲斐", "Reasons to live"],
  ["いきごむ", "意気込む", "Be enthusiastic"],
  ["いきちがい", "行き違 い", "Misunderstanding"],
  ["いくせい", "育成", "Development"],
  ["いくた", "幾多", "Many"],
  ["いける", "生ける・活ける「花を〜」", "Arranging flowers"],
  ["いけん", "異見", "Dissenting opinion"],
  ["いこう", "移行", "transfer"],
  ["いこう", "意向", "inclination"],
  ["いざ", "いざ", "Now"],
  ["いじ", "意地", "Will"],
  ["いじゅう", "移住", "Migration"],
  ["いしょう", "衣装", "Costume"],
  ["いじる", "弄る", "Toy"],
  ["いせい", "異性", "Opposite sex"],
  ["いせき", "遺跡", "Ruins"],
  ["いぜん", "依然", "still"],
  ["いぞん", "依存", "dependence"],
  ["いたく", "委託", "Consignment"],
  ["いただき", "頂「山の〜」", "Peak"],
  ["いたって", "至って", "Until now"],
  ["いためる", "炒める", "Stir-fry"],
  ["いためる", "傷める", "damage"],
  ["いたわる", "労わる", "Work hard"],
  ["いち", "市", "city"],
  ["いち", "位置", "position"],
  ["いちがいに", "一概に", "In general"],
  ["いちじるしい", "著しい", "Significant"],
  ["いちどう", "一同", "Everyone"],
  ["いちぶぶん", "一部分", "Part"],
  ["いちめん", "一面", "One side"],
  ["いちもく", "一目", "glance"],
  ["いちよう", "一様", "Uniform"],
  ["いちりつ", "一律", "Uniform"],
  ["いちれん", "一連", "A series"],
  ["いっかつ", "一括", "Bulk"],
  ["いっき", "一気", "All at once"],
  ["いっきょに", "一挙に", "All at once"],
  ["いっけん", "一見", "At first glance"],
  ["いっさい", "一切", "Nothing"],
  ["いっしん", "一心", "One Mind"],
  ["いっそ", "いっそ", "Better yet"],
  ["いったい", "一帯", "The area"],
  ["いっぺん", "一変", "Complete change"],
  ["いと", "意図", "intention"],
  ["いどう", "異動", "Transfer"],
  ["いとなむ", "営む", "run"],
  ["いどむ", "挑む", "Challenge"],
  ["いなびかり", "稲光", "lightning"],
  ["いのり", "祈り", "prayer"],
  ["いびき", "鼾", "snoring"],
  ["いまさら", "今更", "now"],
  ["いまだ", "未だ", "still"],
  ["いみん", "移民", "Immigration"],
  ["いやいや", "嫌々", "unwillingly"],
  ["いやしい", "卑しい", "vile"],
  ["いやに", "いやに", "Unpleasant"],
  ["いやらしい", "嫌らしい", "Nasty"],
  ["いよく", "意欲", "Motivation"],
  ["いりょう", "衣料", "Clothing"],
  ["いりょく", "威力", "power"],
  ["いるい", "衣類", "clothing"],
  ["いろん", "異論", "Objections"],
  ["いんかん", "印鑑", "signature stamp"],
  ["いんき", "陰気", "Gloomy"],
  ["いんきょ", "隠居", "Retirement"],
  ["インターチェンジ", "interchange", "interchange"],
  ["インターナショナル", "international", "international"],
  ["インターフォン", "interphone", "Interphone"],
  ["インテリ", "intelligentsiya", "intelligentsia"],
  ["インフォメーション", "information", "information"],
  ["インフレ", "inflation", "inflation"],
  ["ウイルス", "virus", "virus"],
  ["うかる", "受かる", "Pass"],
  ["うけいれ", "受け入れ", "accept"],
  ["うけいれる", "受け入れる", "accept"],
  ["うけつぐ", "受け継ぐ", "inherit"],
  ["うけつける", "受け付ける", "accept"],
  ["うけとめる", "受け止める", "Accept"],
  ["うけみ", "受身", "passive"],
  ["うけもち", "受け持ち", "In charge"],
  ["うごき", "動き", "Movement"],
  ["うず", "渦", "Vortex"],
  ["うずめる", "埋める", "fill in"],
  ["うそつき", "嘘つき", "Liar"],
  ["うたたね", "転寝", "Taking a nap"],
  ["うちあける", "打ち明ける", "be frank"],
  ["うちきる", "打ち切る", "abort"],
  ["うちけし", "打ち消し", "Cancellation"],
  ["うちこむ", "打ち込む", "Dedicate"],
  ["うちわ", "団扇", "fan"],
  ["うちわけ", "内訳", "breakdown"],
  ["うつし", "写し", "copy"],
  ["うったえ", "訴え", "Appeal"],
  ["うっとうしい", "鬱陶しい", "Annoying"],
  ["うつむく", "俯く", "Look down"],
  ["うつろ", "空ろ", "hollow"],
  ["うつわ", "器", "vessel"],
  ["うでまえ", "腕前", "Skill"],
  ["うてん", "雨天", "Rainy weather"],
  ["うながす", "促す", "prompt"],
  ["うぬぼれ", "自惚れ", "Self-conceit"],
  ["うまる", "埋まる", "Filled"],
  ["うまれつき", "生まれつき", "Born"],
  ["うむ", "産む", "Give birth"],
  ["うめこむ", "埋め込む", "Embed"],
  ["うめぼし", "梅干", "Pickled plum"],
  ["うらがえし", "裏返し", "Inside Out"],
  ["うりだし", "売り出し", "sale"],
  ["うりだす", "売り出す", "Sell"],
  ["うるおう", "潤う", "Moisturize"],
  ["うわき", "浮気", "Cheating"],
  ["うわまわる", "上回る", "Exceed"],
  ["うわる", "植わる", "Plant"],
  ["うんえい", "運営", "operation"],
  ["うんざり", "うんざり", "fed up"],
  ["うんそう", "運送", "transportation"],
  ["うんちん", "運賃", "fare"],
  ["うんぬん", "云々", "etc."],
  ["うんばん", "運搬", "Transportation"],
  ["うんめい", "運命", "destiny"],
  ["うんゆ", "運輸", "transportation"],
  ["うんよう", "運用", "Operation"],
  ["え", "柄", "Pattern"],
  ["エアメール", "airmail", "Airmail"],
  ["えい", "〜営", "~ Business"],
  ["えいじ", "英字", "Alphabetic"],
  ["えいしゃ", "映写", "projection"],
  ["えいせい", "衛星", "satellite"],
  ["えいぞう", "映像", "picture"],
  ["えいゆう", "英雄", "hero"],
  ["えき", "液", "liquid"],
  ["えつらん", "閲覧", "View"],
  ["えもの", "獲物", "Prey"],
  ["えり", "襟", "collar"],
  ["エレガント", "elegant", "elegant"],
  ["えん", "縁", "edge"],
  ["えんかつ", "円滑", "Smooth"],
  ["えんがわ", "縁側", "Veranda"],
  ["えんがん", "沿岸", "Coast"],
  ["えんきょく", "婉曲", "indirect"],
  ["エンジニア", "engineer", "engineer"],
  ["えんしゅつ", "演出", "Direction"],
  ["えんじる．ずる", "演じる．ずる", "Acting. Cheating."],
  ["えんせん", "沿線", "Along the railway line"],
  ["えんだん", "縁談", "Marriage proposal"],
  ["えんぽう", "遠方", "Far away"],
  ["えんまん", "円満", "Harmonious"],
  ["お", "尾", "tail"],
  ["おいこむ", "追い込む", "Push"],
  ["おいだす", "追い出す", "expel"],
  ["おいる", "老いる", "Growing old"],
  ["おう", "負う", "Lose"],
  ["おうきゅう", "応急", "emergency"],
  ["おうごん", "黄金", "Golden"],
  ["おうしん", "往診", "House Calls"],
  ["おうぼ", "応募", "Application"],
  ["おおかた", "大方", "Mostly"],
  ["おおがら", "大柄", "Large"],
  ["オーケー", "okay", "okay"],
  ["おおげさ", "大袈裟", "exaggerated"],
  ["おおざっぱ", "大雑把", "rough"],
  ["おおすじ", "大筋", "Outline"],
  ["おおぞら", "大空", "Sky"],
  ["オートマチック／オートマティク", "automatic", "Automatic"],
  ["オーバーする", "over", "over"],
  ["おおはば", "大幅", "Significantly"],
  ["オープン", "open", "open"],
  ["おおまか", "大まかな", "Rough"],
  ["おおみず", "大水", "Flood"],
  ["おおやけ", "公", "public"],
  ["おくびょう", "臆病", "Cowardice"],
  ["おくらす", "遅らす", "delay"],
  ["おくれ", "遅れ", "delay"],
  ["おごそか", "厳か", "Solemn"],
  ["おこたる", "怠る", "Neglect"],
  ["おこない", "行ない", "Actions"],
  ["おごる", "奢る「夕食を〜」", "Treat someone to dinner"],
  ["おさまる", "収まる／納まる／治まる", "to settle/to settle/to settle"],
  ["おさん", "お産", "Childbirth"],
  ["おしえ", "教え", "lesson"],
  ["おしきる", "押し切る", "Push through"],
  ["おしこむ", "押し込む", "Push in"],
  ["おしむ", "惜しむ", "regret"],
  ["おしよせる", "押し寄せる", "Flood"],
  ["おす", "雄", "Male"],
  ["おせじ", "お世辞", "compliment"],
  ["おそう", "襲う", "Attack"],
  ["おそくとも", "遅くとも", "At the latest"],
  ["おそれいる", "恐れ入る", "I&#39;m honored"],
  ["おだてる", "煽てる", "flatter"],
  ["おちこむ", "落ち込む", "Depressed"],
  ["おちつき", "落ち着き", "Calmness"],
  ["おちば", "落ち葉", "Fallen leaves"],
  ["おつ", "乙", "B"],
  ["おつかい", "お使い", "Errand"],
  ["おっかない", "おっかない", "scary"],
  ["おてあげ", "お手上げ", "Hands up"],
  ["おどおど", "おどおど", "Timidly"],
  ["おどす", "脅す", "threaten"],
  ["おとずれる", "訪れる", "visit"],
  ["おとも", "お供", "Companion"],
  ["おとろえる", "衰える", "waste away"],
  ["おどろき", "驚き", "surprise"],
  ["おないどし", "同い年", "Same age"],
  ["おのずから", "自ずから", "naturally"],
  ["おびえる", "怯える", "be scared"],
  ["おびただしい", "夥しい", "Numerous"],
  ["おびる", "帯びる", "take on"],
  ["おふくろ", "御袋", "Bag"],
  ["おぼえ", "覚え", "Remember"],
  ["おまけ", "御負け", "Loser"],
  ["おみや", "御宮", "Shrine"],
  ["おむつ", "おむつ", "Diapers"],
  ["おもいつき", "思い付き", "Random Thoughts"],
  ["おもむき", "趣", "Interest"],
  ["おもむく", "赴く", "go"],
  ["おもんじる・ずる", "重んじる・ずる", "Respect and cheat"],
  ["おやじ", "親父", "father"],
  ["および", "及び", "and"],
  ["およぶ", "及ぶ", "reaches"],
  ["オリエンテーション", "orientation", "orientation"],
  ["おりかえす", "折り返す", "Fold back"],
  ["おりもの", "織物", "fabric"],
  ["おる", "織る", "weave"],
  ["おれ", "俺", "I"],
  ["おろか", "愚か", "foolish"],
  ["おろそか", "疎か", "Neglect"],
  ["おんぶ", "負んぶ", "Carrying"],
  ["オンライン", "online", "online"],
  ["おんわ", "温和", "mild"],
  ["が", "〜画", "~Artwork"],
  ["が〜", "画〜", "Painting~"],
  ["カーペット", "carpet", "carpet"],
  ["がい", "〜街", "~ Town"],
  ["かいあく", "改悪", "Deterioration"],
  ["かいうん", "海運", "Maritime"],
  ["がいか", "外貨", "Foreign Currency"],
  ["かいかく", "改革", "reform"],
  ["かいがら", "貝殻", "shell"],
  ["がいかん", "外観", "exterior"],
  ["かいきゅう", "階級", "class"],
  ["かいきょう", "海峡", "Strait"],
  ["かいけん", "会見", "Press Conference"],
  ["かいご", "介護", "nursing care"],
  ["かいさい", "開催", "held"],
  ["かいしゅう", "回収", "collect"],
  ["かいしゅう", "改修", "Renovation"],
  ["かいじゅう", "怪獣", "monster"],
  ["かいじょ", "解除", "Cancellation"],
  ["がいしょう", "外相", "Foreign Minister"],
  ["がいする", "害する", "harm"],
  ["がいせつ", "概説", "Overview"],
  ["かいそう", "回送", "Forwarding"],
  ["かいそう", "階層", "hierarchy"],
  ["かいたく", "開拓", "Development"],
  ["かいだん", "会談", "Meeting"],
  ["かいてい", "改定", "revision"],
  ["かいてい", "改訂", "revision"],
  ["ガイド", "guide", "guide"],
  ["かいどう", "街道", "Highway"],
  ["がいとう", "街頭", "Street"],
  ["がいとう", "該当", "Applicable"],
  ["ガイドブック", "guidebook", "Guidebook"],
  ["かいにゅう", "介入", "intervention"],
  ["がいねん", "概念", "concept"],
  ["かいはつ", "開発", "development"],
  ["かいばつ", "海抜", "Above sea level"],
  ["かいほう", "介抱", "Care"],
  ["かいぼう", "解剖", "Dissection"],
  ["がいよう", "概要", "overview"],
  ["がいらい", "外来", "Outpatient"],
  ["かいらん", "回覧", "pass round for perusal"],
  ["がいりゃく", "概略", "Summary"],
  ["かいりゅう", "海流", "Ocean currents"],
  ["かいりょう", "改良", "Improvements"],
  ["かいろ", "回路", "circuit"],
  ["かいろ", "海路", "By sea"],
  ["かえりみる,省みる、顧みる", "to reflect", " to look back"],
  ["かおつき", "顔付", "Face"],
  ["かがい", "課外", "Extracurricular"],
  ["かかげる", "掲げる", "Raise"],
  ["かかと", "踵", "heel"],
  ["かきとる", "書き取る", "Write it down"],
  ["かきまわす", "掻き回す", "Stir"],
  ["かく", "〜画", "~Artwork"],
  ["かく", "欠く", "Lacking"],
  ["かく", "掻く（恥を〜）", "Scratch (shame)"],
  ["かく", "核", "Nuclear"],
  ["かく", "格", "Rank"],
  ["かく", "角", "corner"],
  ["がくげい", "学芸", "Academic"],
  ["かくさ", "格差", "Inequality"],
  ["かくさん", "拡散", "diffusion"],
  ["がくし", "学士", "Bachelor&#39;s degree"],
  ["かくしゅ", "各種", "Various"],
  ["かくしゅう", "隔週", "Every other week"],
  ["かくしん", "革新", "innovation"],
  ["かくしん", "確信", "Confidence"],
  ["がくせつ", "学説", "theory"],
  ["かくてい", "確定", "Confirm"],
  ["カクテル", "cocktail", "cocktail"],
  ["かくとく", "獲得", "Acquire"],
  ["がくふ", "楽譜", "Sheet Music"],
  ["かくべつ", "格別", "Exceptional"],
  ["かくほ", "確保", "Secure"],
  ["かくめい", "革命", "revolution"],
  ["かくりつ", "確立", "Establishment"],
  ["がくれき", "学歴", "Education"],
  ["かけ", "〜掛け", "~ Hanging"],
  ["かけ", "賭け", "Betting"],
  ["がけ", "崖", "cliff"],
  ["かけ〜", "掛け〜", "Hanging ~"],
  ["かけあし", "駆け足", "Quick Run"],
  ["かけい", "家計", "Household finances"],
  ["かけっこ", "駆けっこ", "race"],
  ["かける", "賭ける", "bet"],
  ["かける", "駆ける", "Run"],
  ["かこう", "加工", "processing"],
  ["かごう", "化合", "Compound"],
  ["かさばる", "嵩張る", "Bulky"],
  ["かさむ", "嵩む", "Bulk up"],
  ["かじょうがき", "箇条書き", "Bullet points"],
  ["かしら", "頭", "head"],
  ["かすか", "微か", "Slight"],
  ["かすむ", "霞む", "Hazy"],
  ["かする", "掠る", "touch lightly"],
  ["かせい", "火星", "Mars"],
  ["かせき", "化石", "fossil"],
  ["かせん", "河川", "Rivers"],
  ["かせん", "化繊", "Chemical Fibers"],
  ["かそ", "過疎", "Depopulation"],
  ["かた〜", "片〜", "One side~"],
  ["かだい", "課題", "assignment"],
  ["かたおもい", "片思い", "Unrequited love"],
  ["かたこと", "片言", "Broken English"],
  ["かたづけ", "片付け", "Tidying up"],
  ["かたむける", "傾ける", "Tilt"],
  ["かためる", "固める", "Harden"],
  ["かたわら", "傍ら", "beside"],
  ["かだん", "花壇", "flower bed"],
  ["かちく", "家畜", "livestock"],
  ["かつ", "且", "And"],
  ["かっき", "画期", "Breakthrough"],
  ["がっくり", "がっくり", "Disappointed"],
  ["がっしょう", "合唱", "Chorus"],
  ["がっしり", "がっしり", "Sturdy"],
  ["がっち", "合致", "Match"],
  ["がっちり", "がっちり", "Sturdy"],
  ["かって", "勝手「台所」", "Kitchen"],
  ["かつて", "嘗て", "Once"],
  ["カット", "cut", "cut"],
  ["かっぱつ", "活発", "Active"],
  ["がっぺい", "合併", "merger"],
  ["カテゴリー", "Kategorie", "Category"],
  ["かなう", "叶う", "come true"],
  ["かなえる", "叶える", "Make it come true"],
  ["かなづち", "金槌", "hammer"],
  [
    "かなわない",
    "適わない／叶わない／敵わない",
    "Not suitable/Not achievable/Not comparable",
  ],
  ["かにゅう", "加入", "Join"],
  ["かねて", "予て", "Forward"],
  ["かばう", "庇う", "protect"],
  ["かぶしき", "株式", "stock"],
  ["かぶれる", "気触れる", "Feel the touch"],
  ["かふん", "花粉", "pollen"],
  ["かへい", "貨幣", "money"],
  ["かまえ", "構え", "Stance"],
  ["かまえる", "構える", "Get Ready"],
  ["かみ", "加味", "Addition"],
  ["かみきる", "噛切る", "Bite off"],
  ["かみつ", "過密", "Overcrowding"],
  ["カムバック", "come back", "come back"],
  ["カメラマン", "cameraman", "Cameraman"],
  ["かゆ", "粥", "Porridge"],
  ["からだつき", "身体付き", "With body"],
  ["からむ", "絡む", "entangle"],
  ["かり", "借り", "Borrow"],
  ["かり", "雁", "Wild Geese"],
  ["かり（に）", "仮（に）", "Tentative"],
  ["カルテ", "Karte", "Karte"],
  ["ガレージ", "garage", "garage"],
  ["かれる", "涸れる", "Dry up"],
  ["かろう", "過労", "overwork"],
  ["かろうじて", "辛うじて", "barely"],
  ["かわす", "交わす", "dodge"],
  ["かわるがわる", "代わる代わる", "One after the other"],
  ["かん", "官", "Government"],
  ["かん", "管", "tube"],
  ["かん", "〜観", "~ View"],
  ["がん", "癌", "cancer"],
  ["かんい", "簡易", "Simple"],
  ["がんか", "眼科", "Ophthalmology"],
  ["かんがい", "灌漑", "Irrigation"],
  ["がんきゅう", "眼球", "Eyeball"],
  ["がんぐ", "玩具", "toy"],
  ["かんけつ", "簡潔", "Concise"],
  ["かんげん", "還元", "reduction"],
  ["かんご", "漢語", "Chinese"],
  ["かんご", "看護", "nursing"],
  ["がんこ", "頑固", "Stubborn"],
  ["かんこう", "刊行", "Publication"],
  ["かんこう", "慣行", "Practices"],
  ["かんこく", "勧告", "Recommendation"],
  ["かんさん", "換算", "Conversion"],
  ["かんしゅう", "観衆", "Spectators"],
  ["かんしゅう", "慣習", "custom"],
  ["がんしょ", "願書", "Application"],
  ["かんしょう", "干渉", "interference"],
  ["がんじょう", "頑丈", "Rugged"],
  ["かんしょく ", "感触", "Feel"],
  ["かんじん", "肝心、肝腎", "The most important thing"],
  ["かんせい", "歓声", "Cheers"],
  ["かんぜい", "関税", "Customs Duties"],
  ["がんせき", "岩石", "rock"],
  ["かんせん", "感染", "infection"],
  ["かんせん", "幹線", "Main Line"],
  ["かんそ", "簡素", "Simple"],
  ["かんてん", "観点", "Viewpoint"],
  ["かんど", "感度", "sensitivity"],
  ["カンニング", "cunning", "cunning"],
  ["がんねん", "元年", "First Year"],
  ["かんぶ ", "幹部", "Executives"],
  ["かんぺき", "完璧", "perfection"],
  ["かんべん", "勘弁", "Please forgive me"],
  ["かんむりょう", "感無量", "Overwhelmed with emotion"],
  ["かんゆう", "勧誘", "Solicitation"],
  ["かんよ", "関与", "Involvement"],
  ["かんよう", "慣用", "Idiomatic"],
  ["かんよう", "寛容", "tolerance"],
  ["がんらい", "元来", "originally"],
  ["かんらん", "観覧", "Viewing"],
  ["かんりょう", "官僚", "Bureaucrat"],
  ["かんれい", "慣例", "Conventions"],
  ["かんれき", "還暦", "Sixtieth birthday"],
  ["かんろく", "貫禄", "dignity"],
  ["かんわ", "緩和", "relief"],
  ["ぎあん", "議案", "Proposal"],
  ["きがい", "危害", "harm"],
  ["きかく", "企画", "plan"],
  ["きかく", "規格", "standard"],
  ["きかざる", "着飾る", "Dress up"],
  ["きがね", "気兼ね", "Feeling uneasy"],
  ["きがる", "気軽", "Casual"],
  ["きかん", "季刊", "quarterly"],
  ["きかん", "器官", "organ"],
  ["きき", "危機", "crisis"],
  ["ききとり", "聞き取り", "Listening"],
  ["ききめ", "効き目", "efficacy"],
  ["ききょう", "帰京", "Returning to Tokyo"],
  ["ききょく", "戯曲", "Play"],
  ["ききん", "基金", "Fund"],
  ["きげき", "喜劇", "comedy"],
  ["ぎけつ", "議決", "Resolution"],
  ["きけん", "棄権", "Abstain"],
  ["きげん", "起源", "origin"],
  ["きこう", "機構", "mechanism"],
  ["きこん", "既婚", "married"],
  ["きざ", "気障", "Disturbance"],
  ["きさい", "記載", "description"],
  ["きざし", "兆し", "signs"],
  ["きしつ", "気質", "temperament"],
  ["きじつ", "期日", "due date"],
  ["ぎじどう", "議事堂", "Parliament Building"],
  ["きしむ", "軋む", "Creak"],
  ["きじゅつ", "記述", "Description"],
  ["きしょう", "気象", "weather"],
  ["きずく", "築く", "build"],
  ["きずつく", "傷付く", "Hurt"],
  ["きずつける", "傷付ける", "Injure"],
  ["きせい", "規制", "Regulation"],
  ["ぎせい", "犠牲", "Sacrifice"],
  ["きせん", "汽船", "Steamship"],
  ["きそう", "競う", "Compete"],
  ["きぞう", "寄贈", "Donation"],
  ["ぎぞう", "偽造", "Counterfeit"],
  ["きぞく", "貴族", "Nobility"],
  ["ぎだい", "議題", "agenda"],
  ["きたえる", "鍛える", "Train"],
  ["きたる", "来る", "come"],
  ["きちっと", "きちっと", "Properly"],
  ["きちょうめん", "几帳面", "Meticulous"],
  ["きっかり", "きっかり", "exactly"],
  ["きっちり", "きっちり", "Tightly"],
  ["きっぱり", "きっぱり", "Decidedly"],
  ["きてい", "規定", "Regulations"],
  ["きてん", "起点", "Starting point"],
  ["きどう", "軌道", "Orbit"],
  ["ぎのう", "技能", "Skills"],
  ["きはん", "規範", "Norms"],
  ["きひん", "気品", "Elegance"],
  ["きふう", "気風", "Atmosphere"],
  ["きふく", "起伏", "Undulations"],
  ["きぼ", "規模", "scale"],
  ["きまぐれ", "気紛れ", "Capricious"],
  ["きまじめ", "生真面目", "Serious"],
  ["きまつ", "期末", "End of term"],
  ["きまりわるい", "決まり悪い", "Embarrassing"],
  ["きめい", "記名", "Name"],
  ["きやく", "規約", "Terms"],
  ["きゃくしょく", "脚色", "Adaptation"],
  ["ぎゃくてん", "逆転", "Reversal"],
  ["きゃくほん", "脚本", "Screenplay"],
  ["きゃしゃ", "華奢", "delicate"],
  ["きゃっかん", "客観", "objective"],
  ["キャッチ", "catch", "catch"],
  ["キャリア", "career", "career"],
  ["きゅうえん", "救援", "relief"],
  ["きゅうがく", "休学", "Leave of absence"],
  ["きゅうきょく", "究極", "ultimate"],
  ["きゅうくつ", "窮屈", "cramped"],
  ["きゅうこん", "球根", "Bulbs"],
  ["きゅうさい", "救済", "Relief"],
  ["きゅうじ", "給仕", "Waiter"],
  ["きゅうしょく", "給食", "School lunch"],
  ["きゅうせん", "休戦", "Truce"],
  ["きゅうち", "旧知", "Old acquaintance"],
  ["きゅうでん", "宮殿", "Palace"],
  ["きゅうぼう", "窮乏", "poverty"],
  ["きゅうりょう", "丘陵", "Hills"],
  ["きよ", "寄与", "contribution"],
  ["きょう", "強", "strength"],
  ["きょう", "〜橋", "~bridge"],
  ["きょうい", "驚異", "Wonder"],
  ["きょうか", "教科", "Subject"],
  ["きょうかい", "協会", "Association"],
  ["きょうがく", "共学", "Coeducational"],
  ["きょうかん", "共感", "empathy"],
  ["きょうぎ", "協議", "Consultation"],
  ["きょうぐう", "境遇", "Circumstances"],
  ["きょうくん", "教訓", "Lessons Learned"],
  ["きょうこう", "強行", "Force"],
  ["きょうこう", "強硬", "Hardline"],
  ["きょうざい", "教材", "Materials"],
  ["きょうさく", "凶作", "Poor harvest"],
  ["ぎょうしゃ", "業者", "Contractor"],
  ["きょうじゅ", "享受", "Enjoyment"],
  ["きょうしゅう", "教習", "Lessons"],
  ["きょうしゅう", "郷愁", "Nostalgia"],
  ["きょうしょく", "教職", "Teaching profession"],
  ["きょうじる．ずる", "興じる．興ずる", "To be amused."],
  ["きょうせい", "強制", "Forced"],
  ["ぎょうせい", "行政", "Government"],
  ["ぎょうせき", "業績", "performance"],
  ["きょうぞん", "共存", "Coexistence"],
  ["きょうち", "境地", "state of mind"],
  ["きょうちょう", "協調", "Cooperation"],
  ["きょうてい", "協定", "Agreement"],
  ["きょうど", "郷土", "Hometown"],
  ["きょうはく", "脅迫", "Threatening"],
  ["ぎょうむ", "業務", "business"],
  ["きょうめい", "共鳴", "Resonance"],
  ["きょうり", "郷里", "Hometown"],
  ["きょうれつ", "強烈", "Intense"],
  ["きょうわ", "共和", "Republic"],
  ["きょくげん", "局限", "Limited"],
  ["きょくたん", "極端", "extreme"],
  ["きょじゅう", "居住", "Residence"],
  ["きょぜつ", "拒絶", "rejection"],
  ["ぎょせん", "漁船", "Fishing vessel"],
  ["ぎょそん", "漁村", "fishing village"],
  ["きょひ", "拒否", "rejection"],
  ["きょよう", "許容", "Tolerance"],
  ["きよらか", "清らか", "Pure"],
  ["きらびやか", "煌びやか", "Gorgeous"],
  ["きり", "切", "Cut"],
  ["きり", "〜きり", "~Kiri"],
  ["ぎり", "義理", "obligation"],
  ["きりかえる", "切替える", "Switch"],
  ["きりゅう", "気流", "air current"],
  ["きれめ", "切れ目", "Break"],
  ["ぎわく", "疑惑", "Suspicion"],
  ["きわめて", "極めて", "extremely"],
  ["きん", "菌", "Bacteria"],
  ["きんがん", "近眼", "Nearsightedness"],
  ["きんきゅう", "緊急", "emergency"],
  ["きんこう", "均衡", "Equilibrium"],
  ["きんこう", "近郊", "Nearby"],
  ["きんし", "近視", "Myopia"],
  ["きんじる・ずる,禁じる・禁ずる", "Forbid", " prohibit"],
  ["きんべん", "勤勉", "Diligence"],
  ["ぎんみ", "吟味", "Examination"],
  ["きんむ", "勤務", "Work"],
  ["きんもつ", "禁物", "Taboo"],
  ["きんろう", "勤労", "Labor"],
  ["く", "苦", "Bitter"],
  ["クイズ", "quiz", "quiz"],
  ["くいちがう", "食い違う", "Disagree"],
  ["くうかん", "空間", "space"],
  ["くうふく", "空腹", "Hunger"],
  ["くかく", "区画", "section"],
  ["くかん", "区間", "section"],
  ["くき", "茎", "Stem"],
  ["くぎり", "区切り", "Separator"],
  ["くぐる", "潜る", "dive"],
  ["くじ", "籤「〜を引く」", "Lottery"],
  ["くじびき", "籤引き", "lottery"],
  ["くすぐったい", "擽ったい", "Tickling"],
  ["ぐち", "愚痴", "Complaints"],
  ["くちずさむ", "口ずさむ", "Humming"],
  ["くちばし", "嘴", "beak"],
  ["くちる", "朽ちる", "Decay"],
  ["くつがえす", "覆す", "Overturn"],
  ["くっきり", "くっきり", "clearly"],
  ["くっせつ", "屈折", "refraction"],
  ["ぐっと", "ぐっと", "much"],
  ["くびかざり", "首飾り", "necklace"],
  ["くびわ", "首輪", "Collar"],
  ["くみあわせる", "組み合わせる", "combine"],
  ["くみこむ", "組み込む", "Incorporate"],
  ["くら", "蔵", "Storehouse"],
  ["グレー", "gray", "Gray"],
  ["クレーン", "crane", "Crane"],
  ["くろうと", "玄人", "expert"],
  ["くろじ", "黒字", "Profit"],
  ["ぐん", "群", "group"],
  ["ぐんかん", "軍艦", "Warship"],
  ["ぐんじ", "軍事", "Military"],
  ["くんしゅ", "君主", "Monarch"],
  ["ぐんしゅう,群集、群衆", "Crowd", " crowd"],
  ["ぐんび", "軍備", "Military"],
  ["ぐんぷく", "軍服", "Military uniform"],
  ["けい", "刑", "Punishment"],
  ["けい", "〜系", "~system"],
  ["げい", "芸", "Art"],
  ["けいい", "経緯", "background"],
  ["けいか", "経過", "Progress"],
  ["けいかい", "警戒", "Vigilance"],
  ["けいかい", "軽快", "Lightweight"],
  ["けいき", "計器", "Instrumentation"],
  ["けいぐ,敬具", "Sincerely", ""],
  ["けいげん", "軽減", "Mitigation"],
  ["けいさい", "掲載", "publish"],
  ["けいしゃ", "傾斜", "Tilt"],
  ["けいせい", "形成", "Formation"],
  ["けいせい", "形勢", "Situation"],
  ["けいそつ", "軽率", "Reckless"],
  ["けいたい", "形態", "form"],
  ["けいたい", "携帯", "cell phone"],
  ["けいばつ", "刑罰", "punishment"],
  ["けいひ", "経費", "Expenses"],
  ["けいぶ", "警部", "Police Inspector"],
  ["けいべつ", "軽蔑", "Contempt"],
  ["けいれき", "経歴", "Biography"],
  ["けいろ", "経路", "route"],
  ["ケース", "case「場合・状況」", "case &quot;case/situation&quot;"],
  ["けがらわしい", "汚らわしい", "filthy"],
  ["げきだん", "劇団", "Theatre company"],
  ["げきれい", "激励", "encouragement"],
  ["ゲスト", "guest", "Guest"],
  ["けだもの", "獣", "beast"],
  ["けつ", "決", "Decision"],
  ["けつい", "決意", "Determination"],
  ["けっかく", "結核", "tuberculosis"],
  ["けっかん", "血管", "Blood vessels"],
  ["けつぎ", "決議", "resolution"],
  ["けっこう", "決行", "carry out"],
  ["けつごう", "結合", "Join"],
  ["けっさん", "決算", "settlement of accounts"],
  ["げっしゃ", "月謝", "Monthly Fee"],
  ["けつじょ", "欠如", "lack"],
  ["けっしょう", "決勝", "final"],
  ["けっしょう", "結晶", "crystal"],
  ["けっせい", "結成", "Formation"],
  ["けっそく", "結束", "Unity"],
  ["げっそり", "げっそり", "Emaciated"],
  ["けつだん", "決断", "decision"],
  ["げっぷ", "月賦", "Monthly installments"],
  ["けつぼう", "欠乏", "deficiency"],
  ["けとばす", "蹴飛ばす", "kick"],
  ["けなす", "貶す", "belittle"],
  ["けむたい", "煙たい", "smoky"],
  ["けむる", "煙る", "Smoke"],
  ["けもの", "獣", "beast"],
  ["けらい", "家来", "Servant"],
  ["げり", "下痢", "diarrhea"],
  ["けん", "件", "subject"],
  ["けん", "〜圏", "~ area"],
  ["けんい", "権威", "authority"],
  ["けんぎょう", "兼業", "Concurrent employment"],
  ["げんけい", "原型・原形", "Prototype/Original Form"],
  ["けんげん", "権限", "authority"],
  ["げんこう", "現行", "current"],
  ["けんざい", "健在", "Alive"],
  ["げんさく", "原作", "original work"],
  ["けんじ", "検事", "Prosecutor"],
  ["げんし", "原子", "atom"],
  ["げんしゅ", "元首", "Head of State"],
  ["げんしょ", "原書", "Original Book"],
  ["けんしょう", "懸賞", "Sweepstakes"],
  ["げんしょう", "減少", "Decreased"],
  ["けんぜん", "健全", "Healthy"],
  ["げんそ", "元素", "element"],
  ["げんぞう", "現像", "developing"],
  ["げんそく", "原則", "principle"],
  ["けんち", "見地", "Perspective"],
  ["げんち", "現地", "local"],
  ["げんてい", "限定", "limited"],
  ["げんてん", "減点", "Deductions"],
  ["げんてん", "原典", "Source"],
  ["げんてん", "原点", "origin"],
  ["げんばく", "原爆", "Atomic Bomb"],
  ["げんぶん", "原文", "Original Text"],
  ["げんみつ", "厳密", "Strict"],
  ["けんめい", "賢明", "Wise"],
  ["けんやく", "倹約", "Frugality"],
  ["げんゆ", "原油", "crude oil"],
  ["けんよう", "兼用", "Dual Use"],
  ["けんりょく", "権力", "power"],
  ["げんろん", "言論", "Opinion"],
  ["こ〜", "故", "late"],
  ["ごい", "語彙", "vocabulary"],
  ["こいする", "恋する", "In love"],
  ["こう", "甲", "A"],
  ["こう", "〜光", "~light"],
  ["こうい", "好意", "Good feeling"],
  ["こうい", "行為", "Actions"],
  ["ごうい", "合意", "agreement"],
  ["こうえき", "交易", "trade"],
  ["こうえん", "公演", "Performance"],
  ["こうかい", "公開", "Published"],
  ["こうかい", "後悔", "regret"],
  ["こうかい", "航海", "Voyage"],
  ["こうがく", "工学", "engineering"],
  ["こうぎ", "抗議", "protest"],
  ["ごうぎ", "合議", "Discussion"],
  ["こうきょ", "皇居", "Imperial Palace"],
  ["こうきょう", "好況", "Prosperity"],
  ["こうぎょう", "興業", "Enterprise"],
  ["こうぎょう", "鉱業", "Mining"],
  ["こうげん", "高原", "Plateau"],
  ["こうご", "交互", "Alternating"],
  ["こうこうと", "煌々と", "Brightly"],
  ["こうこがく", "考古学", "Archaeology"],
  ["こうさく", "工作", "Craft"],
  ["こうさく", "耕作", "Cultivation"],
  ["こうざん", "鉱山", "mine"],
  ["こうしゅう", "講習", "Training"],
  ["こうじゅつ", "口述", "Dictate"],
  ["こうじょ", "控除", "deduction"],
  ["こうしょう", "交渉", "negotiation"],
  ["こうしょう", "高尚", "Noble"],
  ["こうじょう", "向上", "Improvement"],
  ["こうしん", "行進", "march"],
  ["こうしんりょう", "香辛料", "Spices"],
  ["こうすい", "降水", "precipitation"],
  ["こうずい", "洪水", "flood"],
  ["ごうせい", "合成", "Synthesis"],
  ["こうぜん", "公然", "Public"],
  ["こうそう", "抗争", "Conflict"],
  ["こうそう", "構想", "Concept"],
  ["こうそく", "拘束", "Constraints"],
  ["こうたい", "後退", "Retreat"],
  ["こうたい", "交代", "Substitution"],
  ["こうたく", "光沢", "Gloss"],
  ["こうだん", "公団", "Public Corporation"],
  ["こうちょう", "好調", "Good"],
  ["こうとう", "口頭", "oral"],
  ["こうどく", "講読", "Subscribe"],
  ["こうどく", "購読", "Subscribe"],
  ["こうにゅう", "購入", "purchase"],
  ["こうにん", "公認", "Officially Recognized"],
  ["こうねつひ", "光熱費", "Utility expenses"],
  ["こうはい", "荒廃", "Devastation"],
  ["こうばい", "購買", "purchase"],
  ["こうはん", "後半", "Second Half"],
  ["こうひょう", "好評", "Popular"],
  ["こうふ", "交付", "Issuance"],
  ["こうふん", "興奮", "excitement"],
  ["こうぼ", "公募", "Public recruitment"],
  ["こうみょう", "巧妙", "Clever"],
  ["こうよう", "公用", "Official"],
  ["こうり", "小売", "retail"],
  ["こうりつ", "公立", "public"],
  ["こうりつ", "効率", "efficiency"],
  ["ごえい", "護衛", "escort"],
  ["コーナー", "corner", "corner"],
  ["こがら", "小柄", "Petite"],
  ["こぎって", "小切手", "check"],
  ["ごく", "語句", "phrase"],
  ["こくさん", "国産", "Domestic"],
  ["こくてい", "国定", "National"],
  ["こくど", "国土", "National territory"],
  ["こくはく", "告白", "Confession"],
  ["こくぼう", "国防", "National Defense"],
  ["こくゆう", "国有", "Nationalized"],
  ["ごくらく", "極楽", "Paradise"],
  ["こくれん", "国連", "united nations"],
  ["こげちゃ", "焦げ茶", "Dark brown"],
  ["ごげん", "語源", "Etymology"],
  ["ここ", "個々", "individual"],
  ["ここち", "心地", "Comfort"],
  ["こころえ", "心得", "Knowledge"],
  ["こころがけ", "心掛け", "Attention"],
  ["こころがける", "心掛ける", "Keep in mind"],
  ["こころざし", "志", "Ambition"],
  ["こころざす", "志す", "Aim"],
  ["こころづよい", "心強い", "Reassuring"],
  ["こころぼそい", "心細い", "Feeling lonely"],
  ["こころみ", "試み", "Attempt"],
  ["こころみる", "試みる", "Try"],
  ["こころよい", "快い", "pleasant"],
  ["ごさ", "誤差", "error"],
  ["ございます。", "御座います。", "Yes there is."],
  ["こじ", "孤児", "Orphan"],
  ["こじれる", "拗れる", "Be complicated"],
  ["こじん", "故人", "deceased"],
  ["こす", "濾す「水をこす」", "Filter"],
  ["こずえ", "梢", "Kozue"],
  ["こせい", "個性", "Personality"],
  ["こせき", "戸籍", "Family register"],
  ["こぜに", "小銭", "coin"],
  ["こだい", "古代", "Ancient"],
  ["こたつ", "火燵", "Heating table"],
  ["こだわる", "拘る", "Be particular"],
  ["こちょう", "誇張", "Exaggeration"],
  ["こつ", "こつ（〜をつかむ）", "Get the hang of something"],
  ["こっけい", "滑稽", "Humorous"],
  ["こっこう", "国交", "Diplomatic relations"],
  ["こっとうひん", "骨董品", "Antiques"],
  ["こてい", "固定", "Fixation"],
  ["ことがら", "事柄", "Matter"],
  ["こどく", "孤独", "Solitude"],
  ["ことごとく", "悉く", "All"],
  ["ことづて", "言伝", "Message"],
  ["ことに", "殊に", "Especially"],
  ["ことによると", "事に依ると", "It depends"],
  ["こなごな", "粉々", "Shattered"],
  ["このましい", "好ましい", "preferable"],
  ["ごばん", "碁盤", "Go board"],
  ["こべつ", "個別", "Individual"],
  ["コマーシャル", "commercial", "Commercial"],
  ["ごまかす", "誤魔化す", "To conceal"],
  ["こまやか", "細やか、濃やか", "friendly, heartfelt, tender"],
  ["コメント", "comment", "Comment"],
  ["こもる", "籠もる", "Stay indoors"],
  ["こゆう", "固有", "Unique"],
  ["こよう", "雇用", "employment"],
  ["こよみ", "暦", "calendar"],
  ["こらす", "凝らす", "to devote"],
  ["ごらんなさい。", "御覧なさい。", "Take a look."],
  ["こりつ", "孤立", "Isolation"],
  ["こりる", "懲りる", "be punished"],
  ["こる", "凝る", "Be engrossed"],
  ["こんき", "根気", "Perseverance"],
  ["こんきょ", "根拠", "basis"],
  ["こんけつ", "混血", "Mixed race"],
  ["コンタクト（レンズ）", "contact lens", "Contact Lenses"],
  ["こんちゅう", "昆虫", "insect"],
  ["こんてい", "根底", "Foundation"],
  ["コンテスト", "contest", "contest"],
  ["こんどう", "混同", "Confusion"],
  ["コントラスト", "contrast", "contrast"],
  ["コントロール", "control", "control"],
  ["コンパス", "kompas", "kompas"],
  ["こんぽん", "根本", "root"],
  ["さ", "さ", "difference"],
  ["ざい", "財", "Wealth"],
  ["さいかい", "再会", "Reunion"],
  ["さいがい", "災害", "disaster"],
  ["さいきん", "細菌", "Bacteria"],
  ["さいく", "細工", "Craftsmanship"],
  ["さいくつ", "採掘", "mining"],
  ["サイクル", "cycle", "cycle"],
  ["さいけつ", "採決", "vote"],
  ["さいけん", "再建", "Reconstruction"],
  ["さいげん", "再現", "Reproduce"],
  ["ざいげん", "財源", "Funding"],
  ["ざいこ", "在庫", "stock"],
  ["さいさん", "採算", "Profitability"],
  ["さいしゅう", "採集", "Collection"],
  ["サイズ", "size", "size"],
  ["さいせい", "再生", "reproduction"],
  ["ざいせい", "財政", "Finance"],
  ["さいぜん", "最善", "Best"],
  ["さいたく", "採択", "Adoption"],
  ["さいばい", "栽培", "Cultivation"],
  ["さいはつ", "再発", "recurrence"],
  ["さいぼう", "細胞", "cell"],
  ["さいよう", "採用", "Recruitment"],
  ["さえぎる", "遮る", "Block"],
  ["さえずる", "囀る", "sing"],
  ["さえる", "冴える", "Sharp"],
  ["さお", "竿", "rod"],
  ["さかえる", "栄える", "Thrive"],
  ["さがく", "差額", "difference"],
  ["さかずき", "杯", "Cup"],
  ["さかだち", "逆立ち", "Handstand"],
  ["さぎ", "詐欺", "scam"],
  ["さきに,先に、前に「以前」", "Before", " before &quot;previously&quot;"],
  ["さく", "作", "Work"],
  ["さく", "柵", "fence"],
  ["さく", "策", "Measures"],
  ["さくげん", "削減", "Reduction"],
  ["さくご", "錯誤", "Mistake"],
  ["さくせん", "作戦", "Operation"],
  ["さけび", "叫び", "Scream"],
  ["さける", "裂ける", "Tear"],
  ["ささげる", "捧げる", "devote"],
  ["さしかかる", "差し掛かる", "Approaching"],
  ["さしず", "指図", "Instructions"],
  ["さしだす", "差し出す", "Offer"],
  ["さしつかえる", "差し支える", "to interfere"],
  ["さしひき", "差し引き", "subtraction"],
  ["さずける", "授ける", "bestow"],
  ["さする", "擦る", "rub"],
  ["さぞ,さぞ「さぞや、さぞかし」,&quot;Yes", " yes", " yes&quot;"],
  ["さだまる", "定まる", "Determined"],
  ["さだめる", "定める", "stipulate"],
  ["ざだんかい", "座談会", "Roundtable discussion"],
  ["ざつ", "雑", "rough"],
  ["ざっか", "雑貨", "miscellaneous goods"],
  ["さっかく", "錯覚", "Illusion"],
  ["さつじん", "殺人", "murder"],
  ["さっする", "察する", "guess"],
  ["ざつだん", "雑談", "Chat"],
  ["さっと", "さっと", "suddenly"],
  ["さっぱりする", "さっぱりする", "Refreshing"],
  ["さとる", "悟る", "Realize"],
  ["さなか", "最中", "During"],
  ["さばく", "裁く", "judge"],
  ["ざひょう", "座標", "coordinate"],
  ["さほど", "然程", "About"],
  ["サボる", "sabotage", "sabotage"],
  ["さま", "様", "Mr."],
  ["さまよう", "彷徨う", "Wandering"],
  ["さむけ", "寒気", "cold"],
  ["さむさい", "侍", "samurai"],
  ["さも", "然も", "Of course"],
  ["さよう", "作用", "Action"],
  ["さらう", "攫う「子供を〜」", "Kidnap &quot;children&quot;"],
  ["さわる", "障る", "Obstruct"],
  ["さん", "酸", "acid"],
  ["さんか", "酸化", "Oxidation"],
  ["さんがく", "山岳", "Mountain"],
  ["さんぎいん", "参議院", "House of Councillors"],
  ["サンキュー", "thank you", "thank you"],
  ["さんきゅう", "産休", "maternity leave"],
  ["ざんきん", "残金", "Balance"],
  ["さんご", "産後", "Postpartum"],
  ["ざんこく", "残酷", "Cruelty"],
  ["さんしゅつ", "産出", "Yield"],
  ["さんしょう", "参照", "reference"],
  ["さんじょう", "参上", "Here I am"],
  ["ざんだか", "残高", "balance"],
  ["サンタクロース", "Santa Claus", "Santa Claus"],
  ["さんちょう", "山頂", "Summit"],
  ["さんばし", "桟橋", "Pier"],
  ["さんび", "賛美", "praise"],
  ["さんぷく", "山腹", "Mountainside"],
  ["さんふじんか", "産婦人科", "Obstetrics and Gynecology"],
  ["さんぶつ", "産物", "product"],
  ["さんみゃく", "山脈", "Mountains"],
  ["し", "死", "death"],
  ["し", "師", "Teacher"],
  ["し", "〜士", "~man, person"],
  ["じ", "〜次", "~Next"],
  ["じ", "〜児", "~child"],
  ["しあがり", "仕上がり", "finish"],
  ["しあげ", "仕上げ", "Finishing"],
  ["しあげる", "仕上げる", "Finishing touches"],
  ["しいく", "飼育", "Breeding"],
  ["しいて", "強いて", "Force"],
  ["シート", "sheet/seat", "seat/seat"],
  ["ジーパン", "jeans+pants", "jeans + pants"],
  ["しいる", "強いる", "Force"],
  ["しいれる", "仕入れる", "Purchase"],
  ["しお", "潮", "tide"],
  ["しか", "歯科", "Dentistry"],
  ["じが", "自我", "ego"],
  ["しがい", "市街", "city"],
  ["しかく", "視覚", "Vision"],
  ["しかく", "資格", "Qualifications"],
  ["じかく", "自覚", "Awareness"],
  ["しかけ", "仕掛け", "Device"],
  ["しかける", "仕掛ける", "Set up"],
  ["しかしながら", "然し乍ら", "However"],
  ["しき", "指揮", "Conduct"],
  ["じき", "磁器", "porcelain"],
  ["じき", "磁気", "Magnetic"],
  ["しきさい", "色彩", "color"],
  ["しきじょう", "式場", "Ceremony venue"],
  ["しきたり", "仕来り", "custom"],
  ["じぎょう", "事業", "business"],
  ["しきる", "仕切る", "take charge"],
  ["しきん", "資金", "Funding"],
  ["じく", "軸", "shaft"],
  ["しくじる", "しくじる", "screw up"],
  ["しくみ", "仕組", "How it works"],
  ["しけい", "死刑", "Death Penalty"],
  ["しける", "湿気る", "Humid"],
  ["じこ", "自己", "self"],
  ["しこう", "志向", "Aims"],
  ["しこう", "思考", "Thinking"],
  ["しこう", "施行", "Enforcement"],
  ["しこう", "嗜好", "preference"],
  ["しこう", "試行", "Attempt"],
  ["じこう", "事項", "Matters"],
  ["じごく", "地獄", "hell"],
  ["じこくひょう", "時刻表", "Timetable"],
  ["じさ", "時差", "time difference"],
  ["じざい", "自在", "freely"],
  ["しさつ", "視察", "Visit"],
  ["しさん", "資産", "assets"],
  ["しじ", "支持", "support"],
  ["じじつ", "事実", "fact"],
  ["じしゅ", "自首", "Turn yourself in"],
  ["じしゅ", "自主", "Independent"],
  ["ししゅう", "刺繍", "embroidery"],
  ["しじょう", "市場", "market"],
  ["じしょく", "辞職", "Resignation"],
  ["しずく", "雫", "drop"],
  ["システム", "system", "system"],
  ["しずまる", "静まる", "Calm down"],
  ["しずめる", "沈める／鎮める", "to subside/to calm"],
  ["しせつ", "施設", "facility"],
  ["しそく", "子息", "son"],
  ["じぞく", "持続", "Persistence"],
  ["じそんしん", "自尊心", "Self-esteem"],
  ["じたい", "字体", "Font"],
  ["じたい", "辞退", "Decline"],
  ["したう", "慕う", "Admire"],
  ["したごころ", "下心", "ulterior motives"],
  ["したじ", "下地", "Base"],
  ["したしむ", "親しむ", "Become familiar with"],
  ["したしらべ", "下調べ", "Preliminary Research"],
  ["したてる", "仕立てる", "tailor"],
  ["したどり", "下取り", "Trade-in"],
  ["したび", "下火", "Fading"],
  ["じつ", "実", "fruit"],
  ["じっか", "実家", "parents&#39; home"],
  ["しっかく", "失格", "Disqualification"],
  ["しつぎ", "質疑", "Questions and Answers"],
  ["しっきゃく", "失脚", "Downfall"],
  ["じつぎょうか", "実業家", "Businessman"],
  ["シック", "chic", "chic"],
  ["じっくり", "じっくり", "Take your time"],
  ["しつけ", "躾", "Discipline"],
  ["しつける", "躾ける", "Discipline"],
  ["じっしつ", "実質", "Substantial"],
  ["じつじょう", "実情", "The reality"],
  ["じっせん", "実践", "Practice"],
  ["しっそ", "質素", "Spartan"],
  ["じったい", "実態", "Reality"],
  ["しっちょう", "失調", "Ataxia"],
  ["しっと", "嫉妬", "envy"],
  ["じっぴ", "実費", "Actual cost"],
  ["してき", "指摘", "pointing out"],
  ["してん", "視点", "perspective"],
  ["じてん", "自転", "rotation"],
  ["じどうし", "自動詞", "Intransitive"],
  ["しとやか", "淑やか", "graceful"],
  ["しなびる", "萎びる", "wilt"],
  ["しなやか", "しなやか", "Supple"],
  ["シナリオ", "scenario", "scenario"],
  ["しにょう", "屎尿", "human waste"],
  ["じぬし", "地主", "Landlord"],
  ["しのぐ", "凌ぐ", "Survive"],
  ["しば", "芝", "Turf"],
  ["しはつ", "始発", "First train"],
  ["じびか", "耳鼻科", "Otolaryngology"],
  ["しぶい", "渋い", "Astringent"],
  ["しぶつ", "私物", "Personal belongings"],
  ["しぶとい", "渋とい", "Shibutoi"],
  ["しほう", "司法", "Judiciary"],
  ["しぼう", "志望", "Aspirations"],
  ["しぼう", "脂肪", "fat"],
  ["しまつ", "始末", "End"],
  ["しみる", "染みる", "Permeate"],
  ["しみる", "滲みる", "Oozing"],
  ["しめい", "使命", "mission"],
  ["じもと", "地元", "local"],
  ["しや", "視野", "Field of view"],
  ["じゃく", "弱", "weak"],
  ["しゃこう", "社交", "Social"],
  ["しゃざい", "謝罪", "apology"],
  ["ジャズ", "jazz", "jazz"],
  ["しゃぜつ", "謝絶", "Refusal"],
  ["しゃたく", "社宅", "Company housing"],
  ["じゃっかん", "若干", "Some"],
  ["しゃみせん", "三味線", "Shamisen"],
  ["しゃめん", "斜面", "Slope"],
  ["じゃり", "砂利", "gravel"],
  ["しゃれる", "洒落る", "dress stylishly"],
  ["ジャンパー", "jumper", "Jumper"],
  ["ジャンプ", "jump", "jump"],
  ["ジャンボ", "jumbo", "jumbo"],
  ["ジャンル", "genre", "genre"],
  ["しゅ", "主", "main"],
  ["しゅ", "種", "seed"],
  ["しゅう", "衆", "People"],
  ["しゆう", "私有", "Privately owned"],
  ["じゅう", "住", "Living"],
  ["しゅうえき", "収益", "Revenue"],
  ["しゅうがく", "修学", "Study"],
  ["しゅうき", "周期", "period"],
  ["しゅうぎいん", "衆議院", "House of Representatives"],
  ["しゅうぎょう", "就業", "Employment"],
  ["じゅうぎょういん", "従業員", "employee"],
  ["しゅうけい", "集計", "totalling"],
  ["しゅうげき", "襲撃", "Raid"],
  ["しゅうし", "修士", "Master&#39;s degree"],
  ["しゅうし", "終始", "From beginning to end"],
  ["しゅうし", "収支", "Income and Expenditure"],
  ["じゅうじ", "従事", "Engaged"],
  ["しゅうじつ", "終日", "All day"],
  ["じゅうじつ", "充実", "fulfillment"],
  ["しゅうしゅう", "収集", "collection"],
  ["しゅうしょく", "修飾", "Modification"],
  ["じゅうじろ", "十字路", "crossroads"],
  ["じゅうなん", "柔軟", "flexible"],
  ["じゅうふく", "重複", "Duplicates"],
  ["しゅうよう", "収容", "Accommodation"],
  ["じゅうらい", "従来", "Conventional"],
  ["しゅうりょう", "修了", "Completion"],
  ["しゅえい", "守衛", "Guard"],
  ["しゅえん", "主演", "starring"],
  ["しゅかん", "主観", "Subjective"],
  ["しゅぎょう", "修行", "Training"],
  ["じゅく", "塾", "cram school"],
  ["しゅくが", "祝賀", "celebration"],
  ["しゅくめい", "宿命", "Fate"],
  ["しゅげい", "手芸", "Handicrafts"],
  ["しゅけん", "主権", "sovereignty"],
  ["しゅさい", "主催", "Organizer"],
  ["しゅざい", "取材", "Interview"],
  ["しゅし", "趣旨", "Purpose"],
  ["しゅじゅ", "種々", "Various"],
  ["しゅしょく", "主食", "staple food"],
  ["しゅじんこう", "主人公", "main character"],
  ["しゅたい", "主体", "Subject"],
  ["しゅだい", "主題", "subject"],
  ["しゅつえん", "出演", "Cast"],
  ["しゅっけつ", "出血", "bleeding"],
  ["しゅつげん", "出現", "Appearance"],
  ["しゅっさん", "出産", "childbirth"],
  ["しゅっしゃ", "出社", "go to the office"],
  ["しゅっしょう／しゅっせい", "出生", "birth"],
  ["しゅっせ", "出世", "Career advancement"],
  ["しゅつだい", "出題", "Questions"],
  ["しゅつどう", "出動", "Dispatch"],
  ["しゅっぴ", "出費", "Expenses"],
  ["しゅっぴん", "出品", "Listing"],
  ["しゅどう", "主導", "Leading"],
  ["しゅにん", "主任", "Chief"],
  ["しゅのう", "首脳", "Leaders"],
  ["しゅび", "守備", "Defense"],
  ["しゅほう", "手法", "Methodology"],
  ["じゅもく", "樹木", "Trees"],
  ["じゅりつ", "樹立", "Establishment"],
  ["じゅんきゅう", "準急", "Semi-express"],
  ["じゅんじる・ずる,準じる、準ずる", "conform to", " conform to"],
  ["しょ", "〜書", "~ Calligraphy"],
  ["しょう", "〜症", "~ Disease"],
  ["しょう", "〜証", "~proof"],
  ["しよう", "仕様", "specification"],
  ["しよう", "私用", "Personal"],
  ["じょう", "情", "Emotion"],
  ["じょう", "〜嬢", "~Miss"],
  ["じょう", "〜条", "~ Article"],
  ["じょうい", "上位", "Top"],
  ["じょうえん", "上演", "Performance"],
  ["じょうか", "城下", "Castle town"],
  ["しょうがい", "生涯", "Life"],
  ["しょうきょ", "消去", "erase"],
  ["じょうくう", "上空", "Above"],
  ["しょうげき", "衝撃", "impact"],
  ["しょうげん", "証言", "testimony"],
  ["しょうこ", "証拠", "evidence"],
  ["しょうごう", "照合", "Matching"],
  ["しょうさい", "詳細", "detail"],
  ["じょうし", "上司", "boss"],
  ["じょうしょう", "上昇", "rise"],
  ["しょうしん", "昇進", "promotion"],
  ["しょうする", "称する", "Calling"],
  ["じょうせい", "情勢", "situation"],
  ["しょうそく", "消息", "News"],
  ["しょうたい", "正体", "Identity"],
  ["しょうだく", "承諾", "Acceptance"],
  ["じょうちょ／じょうしょ", "情緒", "Emotion"],
  ["しょうちょう", "象徴", "Symbolism"],
  ["しょうにか", "小児科", "Pediatrics"],
  ["しょうにん", "証人", "witness"],
  ["しようにん", "使用人", "Servant"],
  ["じょうねつ", "情熱", "passion"],
  ["じょうほ", "譲歩", "Concessions"],
  ["しょうめい", "照明", "illumination"],
  ["じょうやく", "条約", "Treaty"],
  ["しょうり", "勝利", "victory"],
  ["じょうりく", "上陸", "landing"],
  ["じょうりゅう", "蒸留", "distillation"],
  ["しょうれい", "奨励", "Encouragement"],
  ["ショー", "show", "show"],
  ["じょがい", "除外", "Exclusion"],
  ["しょくいん", "職員", "Staff"],
  ["しょくみんち", "植民地", "Colony"],
  ["しょくむ", "職務", "Job"],
  ["しょくん,諸君", "Gentlemen", ""],
  ["しょげん", "助言", "advice"],
  ["じょこう", "徐行", "slow down"],
  ["しょざい", "所在", "Location"],
  ["しょじ", "所持", "Possession"],
  ["じょし", "助詞", "particle"],
  ["じょし", "女史", "Ms."],
  ["しょぞく", "所属", "Affiliation"],
  ["しょち", "処置", "treatment"],
  ["ショック", "shock", "shock"],
  ["しょっちゅう", "しょっちゅう", "Frequently"],
  ["しょてい", "所定", "Prescribed"],
  ["じょどうし", "助動詞", "Auxiliary verbs"],
  ["しょとく", "所得", "income"],
  ["しょばつ", "処罰", "Punishment"],
  ["しょはん", "初版", "First Edition"],
  ["しょひょう", "書評", "Book Review"],
  ["しょぶん", "処分", "disposal"],
  ["しょみん", "庶民", "Common people"],
  ["しょむ", "庶務", "General Affairs"],
  ["しょゆう", "所有", "Ownership"],
  ["しらべ", "調べ", "Research"],
  ["じりつ", "自立", "Independence"],
  ["しるす", "記す", "Write down"],
  ["しれい", "指令", "Directive"],
  ["しん", "〜心", "~heart"],
  ["じん", "陣", "Team"],
  ["しんか", "進化", "evolution"],
  ["じんかく", "人格", "personality"],
  ["しんぎ", "審議", "Deliberations"],
  ["しんこう", "振興", "Promotion"],
  ["しんこう", "進行", "Progress"],
  ["しんこう", "新興", "Emerging"],
  ["しんこく", "申告", "Declaration"],
  ["しんこん", "新婚", "newlywed"],
  ["しんさ", "審査", "examination"],
  ["じんざい", "人材", "Human Resources"],
  ["しんし", "紳士", "Gentleman"],
  ["しんじつ", "真実", "truth"],
  ["しんじゃ", "信者", "Believer"],
  ["しんじゅ", "真珠", "pearl"],
  ["しんじゅう", "心中", "Suicide"],
  ["しんしゅつ", "進出", "Advance"],
  ["しんじょう", "心情", "Feelings"],
  ["しんじん", "新人", "Newcomers"],
  ["しんせい", "神聖", "sacred"],
  ["しんぜん", "親善", "Goodwill"],
  ["しんそう", "真相", "truth"],
  ["じんそく", "迅速", "Rapid"],
  ["じんたい", "人体", "human body"],
  ["しんちく", "新築", "New Construction"],
  ["しんてい", "進呈", "Present"],
  ["しんてん", "進展", "Progress"],
  ["しんでん", "神殿", "temple"],
  ["しんど", "進度", "progress"],
  ["しんどう", "振動", "vibration"],
  ["しんにゅうせい", "新入生", "Freshman"],
  ["しんにん", "信任", "confidence"],
  ["しんねん", "信念", "belief"],
  ["しんぴ", "神秘", "Mystery"],
  ["しんぼう", "辛抱", "patience"],
  ["じんみん", "人民", "People"],
  ["しんり", "真理", "truth"],
  ["しんりゃく", "侵略", "Invasion"],
  ["しんりょう", "診療", "Treatment"],
  ["しんろ", "進路", "course"],
  ["すい", "粋", "Cool"],
  ["すいげん", "水源", "Water Source"],
  ["すいしん", "推進", "Propulsion"],
  ["すいせん", "水洗", "Water washing"],
  ["すいそう", "吹奏", "Wind Instrument"],
  ["すいそく", "推測", "Speculation"],
  ["すいでん", "水田", "Rice Field"],
  ["すいり", "推理", "Deduction"],
  ["すうし", "数詞", "numeral"],
  ["すうはい", "崇拝", "worship"],
  ["すえつける", "据付ける", "Install"],
  ["すえる", "据える", "Place"],
  ["すがすがしい", "清々しい", "refreshing"],
  ["すくい", "救い", "Salvation"],
  ["すくう", "掬う「水を〜」", "Scoop up the water"],
  ["すこやか", "健やか", "healthy"],
  ["すすぐ", "濯ぐ", "rinse"],
  ["すすみ", "進み", "Progress"],
  ["すすめ", "勧め", "Recommendation"],
  ["すそ", "裾", "hem"],
  ["スタジオ", "studio", "studio"],
  ["すたれる", "廃れる", "Fall into disuse"],
  ["スチーム", "steam", "steam"],
  ["スト", "strike", "strike"],
  ["ストライキ", "strike", "strike"],
  ["ストレス", "stress", "stress"],
  ["ストロー", "straw", "straw"],
  ["ストロボ", "strobo", "Strobe"],
  ["すばしこい", "すばしこい", "Agility"],
  ["すばやい", "素早い", "quick"],
  ["ずばり", "ずばり", "Exactly"],
  ["ずぶぬれ", "ずぶ濡れ", "soaked"],
  ["スプリング", "spring", "spring"],
  ["スペース", "space", "space"],
  ["スポーツカー", "sports car", "Sports car"],
  ["すます,澄ます、清ます", "To clarify", " to purify"],
  ["すます", "済ます", "Complete"],
  ["すみやか", "速やかに", "Promptly"],
  ["スラックス", "slacks", "slacks"],
  ["ずらっと", "ずらっと", "Lined up"],
  ["する", "擦る", "rub"],
  ["ずるずる", "ずるずる", "Slither"],
  ["ずれ", "滑れ", "Slide"],
  ["すれちがい", "擦れ違い", "Misunderstanding"],
  ["すれる", "擦れる", "rub"],
  ["すんなり", "すんなり", "Smoothly"],
  ["せい", "〜制", "~"],
  ["せいいく,生育、成育", "Growth", " development"],
  ["せいか", "成果", "Results"],
  ["せいかい", "正解", "correct answer"],
  ["せいき", "正規", "regular"],
  ["せいぎ", "正義", "justice"],
  ["せいけい", "生計", "living"],
  ["せいけん", "政権", "administration"],
  ["せいこう", "精巧", "Exquisite"],
  ["せいざ", "星座", "constellation"],
  ["せいさい", "制裁", "Sanctions"],
  ["せいさく", "政策", "policy"],
  ["せいさん", "精算", "settlement"],
  ["せいし", "静止", "Still"],
  ["せいし", "生死", "Life and Death"],
  ["せいじつ", "誠実", "sincerity"],
  ["せいじゅく", "成熟", "Maturity"],
  ["せいしゅん", "青春", "Youth"],
  ["せいじゅん", "清純", "Pure"],
  ["せいしょ", "聖書", "Bible"],
  ["せいじょう", "正常", "normal"],
  ["せいする", "制する", "Control"],
  ["せいぜん", "整然", "Orderly"],
  ["せいそう", "盛装", "Dressed up"],
  ["せいだい", "盛大", "Grand"],
  ["せいだく", "清濁", "good and evil"],
  ["せいてい", "制定", "Enactment"],
  ["せいてき", "静的", "static"],
  ["せいてつ", "製鉄", "Steel"],
  ["せいてん", "晴天", "Sunny"],
  ["せいとう", "正当", "Right"],
  ["せいねん", "成年", "Adulthood"],
  ["せいふく", "制服", "uniform"],
  ["せいふく", "征服", "Conquest"],
  ["せいほう", "製法", "Manufacturing method"],
  ["せいみつ", "精密", "precision"],
  ["ぜいむしょ", "税務署", "Tax office"],
  ["せいめい", "声明", "statement"],
  ["せいめい", "姓名", "First name"],
  ["せいやく", "制約", "Constraints"],
  ["せいり", "生理", "Physiology"],
  ["せいりょく", "勢力", "force"],
  ["せいれつ", "整列", "Alignment"],
  ["セール", "sale", "Sale"],
  ["せかす", "急かす", "Hurry"],
  ["せがれ", "倅", "Son"],
  ["せきむ", "責務", "Responsibilities"],
  ["セクション", "section", "section"],
  ["せじ", "世辞", "Flattery"],
  ["ぜせい", "是正", "correction"],
  ["せたい", "世帯", "Household"],
  ["せだい", "世代", "generation"],
  ["せつ", "節", "section"],
  ["せっかい", "切開", "Incision"],
  ["セックス", "sex", "sex"],
  ["せつじつ", "切実", "Earnest"],
  ["せっしょく", "接触", "contact"],
  ["せつぞくし", "接続詞", "conjunction"],
  ["せっち", "設置", "Installation"],
  ["せっちゅう", "折衷", "Eclectic"],
  ["せってい", "設定", "setting"],
  ["せっとく", "説得", "Persuasion"],
  ["せつない", "切ない", "painful"],
  ["ぜっぱん", "絶版", "Out of print"],
  ["ぜつぼう", "絶望", "despair"],
  ["せつりつ", "設立", "Established"],
  ["せめ", "攻め", "Attack"],
  ["ゼリー", "jelly", "Jelly"],
  ["セレモニー", "ceremony", "Ceremony"],
  ["せろん", "世論", "public opinion"],
  ["せん", "先", "Destination"],
  ["ぜん", "膳", "Meal"],
  ["ぜん", "禅", "Zen"],
  ["せんい", "繊維", "fiber"],
  ["ぜんかい", "全快", "Fully recovered"],
  ["せんきょう", "宣教", "Mission"],
  ["せんげん", "宣言", "declaration"],
  ["せんこう", "先行", "Leading"],
  ["せんこう", "選考", "Selection"],
  ["せんさい", "戦災", "War damage"],
  ["せんしゅう", "専修", "Specialization"],
  ["せんじゅつ", "戦術", "tactics"],
  ["センス", "sense", "sense"],
  ["せんすい", "潜水", "diving"],
  ["ぜんせい", "全盛", "At its peak"],
  ["せんだい", "先代", "Predecessor"],
  ["せんだって", "先達て", "Predecessor"],
  ["せんちゃく,先着", "First come", " first served"],
  ["せんてい", "前提", "Premise"],
  ["せんてんてき", "先天的", "congenital"],
  ["ぜんと", "前途", "The future"],
  ["せんとう", "戦闘", "battle"],
  ["せんにゅう", "潜入", "Infiltration"],
  ["せんぱく", "船舶", "Ships"],
  ["ぜんはん", "前半", "first half"],
  ["ぜんめつ", "全滅", "Annihilation"],
  ["せんよう", "専用", "exclusive"],
  ["せんりょう", "占領", "occupation"],
  ["ぜんりょう", "善良", "Goodness"],
  ["せんりょく", "戦力", "Forces"],
  ["ぜんれい", "前例", "Precedent"],
  ["そう", "相", "phase"],
  ["そう", "僧", "monk"],
  ["そうおう", "相応", "Appropriate"],
  ["ぞうか", "増加", "increase"],
  ["そうかい", "総会", "General Assembly"],
  ["そうかん", "創刊", "First issue"],
  ["ぞうき", "雑木", "Miscellaneous trees"],
  ["そうきゅう／さっきゅう", "早急", "quickly"],
  ["ぞうきょう", "増強", "Augment"],
  ["そうきん", "送金", "Remittance"],
  ["そうこう", "走行", "Running"],
  ["そうごう", "総合", "General"],
  ["そうさ", "捜査", "investigation"],
  ["そうさく", "捜索", "search"],
  ["そうしつ", "喪失", "loss"],
  ["そうじゅう", "操縦", "Pilot"],
  ["ぞうしょう", "蔵相", "Minister of Finance"],
  ["そうしょく", "装飾", "Decoration"],
  ["ぞうしん", "増進", "Increase"],
  ["そうぞう", "創造", "Creation"],
  ["そうたい", "相対", "relative"],
  ["そうだい", "壮大", "magnificent"],
  ["そうどう", "騒動", "Controversy"],
  ["そうなん", "遭難", "Distress"],
  ["そうば", "相場", "Market price"],
  ["そうび", "装備", "Equipment"],
  ["そうりつ", "創立", "Founded"],
  ["そえる", "添える", "Add"],
  ["ソース", "sauce/source", "Sauce/Sauce"],
  ["そくざに", "即座に", "Immediately"],
  ["そくしん", "促進", "Promotion"],
  ["そくする", "即する", "Immediately"],
  ["そくばく", "束縛", "Bondage"],
  ["そくめん", "側面", "side"],
  ["そこなう", "損なう", "Damage"],
  ["そこら", "其処ら", "Other places"],
  ["そざい", "素材", "material"],
  ["そし", "阻止", "Block"],
  ["そしょう", "訴訟", "Litigation"],
  ["そだち", "育ち", "Upbringing"],
  ["そち", "措置", "Measures"],
  ["ソックス", "socks", "socks"],
  ["そっけない", "素っ気無い", "Uncooperative"],
  ["そっぽ", "外方", "Outside"],
  ["そなえつける", "備え付ける", "Equip"],
  ["そなわる,備わる、具わる", "to be equipped", " to have"],
  ["そびえる", "聳える", "Towering"],
  ["そぼく", "素朴", "Simple"],
  ["そまる", "染まる", "Dye"],
  ["そむく", "背く", "disobey"],
  ["そめる", "染める", "dye"],
  ["そらす", "逸らす", "Divert"],
  ["そり", "橇「〜に乗る」", "Sled &quot;to ride&quot;"],
  ["そる", "反る", "Warp"],
  ["それから", "其れから", "And then"],
  ["ソロ", "solo", "solo"],
  ["そろい", "揃い", "Matching"],
  ["ぞんざい", "ぞんざい", "Careless"],
  ["そんしつ", "損失", "loss"],
  ["そんぞく", "存続", "Survival"],
  ["ダース", "dozen", "thousand"],
  ["たい", "隊", "Squad"],
  ["たい", "〜帯", "~band"],
  ["だいいち", "第一", "First"],
  ["たいおう", "対応", "correspondence"],
  ["たいか", "大家", "Landlord"],
  ["たいか", "退化", "Degeneration"],
  ["たいがい", "大概", "Generally"],
  ["たいかく", "体格", "Physical build"],
  ["たいがく", "退学", "Withdrawal"],
  ["たいきん", "大金", "Big money"],
  ["たいぐう", "待遇", "treatment"],
  ["たいけつ", "対決", "Showdown"],
  ["たいけん", "体験", "Experience"],
  ["たいこう", "対抗", "Opposition"],
  ["たいじ", "退治", "Extermination"],
  ["たいしゅう", "大衆", "The masses"],
  ["たいしょ", "対処", "handle"],
  ["たいしょく", "退職", "retirement"],
  ["だいする", "題する", "Title"],
  ["たいせい", "態勢", "Posture"],
  ["たいだん", "対談", "Interview"],
  ["だいたん", "大胆", "Bold"],
  ["たいとう", "対等", "equal"],
  ["タイトル", "title", "title"],
  ["だいなし", "台無し", "ruin"],
  ["たいのう", "滞納", "Delinquent"],
  ["たいひ", "対比", "Contrast"],
  ["タイピスト", "typist", "typist"],
  ["たいぶ", "大部", "most"],
  ["だいべん", "代弁", "Speak on your behalf"],
  ["だいべん", "大便", "Stool"],
  ["たいぼう", "待望", "Long-awaited"],
  ["だいほん", "台本", "Script"],
  ["タイマー", "timer", "timer"],
  ["たいまん", "怠慢", "procrastination"],
  ["タイミング", "timing", "timing"],
  ["タイム", "time", "time"],
  ["タイムリー", "timely", "timely"],
  ["たいめん", "対面", "face to face"],
  ["だいよう", "代用", "substitute"],
  ["たいりょく", "体力", "Physical Strength"],
  ["タイル", "tile", "tile"],
  ["たいわ", "対話", "Dialogue"],
  ["ダウン", "down", "down"],
  ["たえる", "堪える、耐える", " to endure"],
  ["たえる", "絶える、断える", " to cease"],
  ["だかい", "打開", "Breakthrough"],
  ["たかまる", "高まる", "Increase"],
  ["たきび", "焚き火", "Bonfire"],
  ["だきょう", "妥協", "compromise"],
  ["たくましい", "たくましい", "Robust"],
  ["たくみ", "巧み", "Skillful"],
  ["たけ", "丈", "height"],
  ["だげき", "打撃", "Strike"],
  ["だけつ", "妥結", "Settlement"],
  ["ださく", "駄作", "Bad work"],
  ["たしざん", "足し算", "addition"],
  ["たすうけつ", "多数決", "Majority vote"],
  ["たすけ", "助け", "Help"],
  ["たずさわる", "携わる", "Involved"],
  ["ただよう", "漂う", "Drift"],
  ["たちさる", "立去る", "Leave"],
  ["たちよる", "立寄る", "Stop by"],
  ["たつ", "断つ", "cut off"],
  ["だっこ", "抱っこ", "hug"],
  ["たっしゃ", "達者", "Healthy"],
  ["だっしゅつ", "脱出", "escape"],
  ["たっせい", "達成", "Accomplished"],
  ["だったい", "脱退", "Withdrawal"],
  ["だったら", "だったら", "If so"],
  ["たて", "盾", "shield"],
  ["たてかえる", "立替える", "Pay on behalf of"],
  ["たてまえ", "建前", "Pretense"],
  ["たてまつる", "奉る", "Offer"],
  ["だと", "だと", "That is."],
  ["たどうし", "他動詞", "Transitive"],
  ["たとえ", "例え", "example"],
  ["たどりつく", "辿り着く", "Arrive"],
  ["たどる", "辿る", "follow"],
  ["たばねる", "束ねる", "Bundle"],
  ["だぶだぶ", "だぶだぶ", "Baggy"],
  ["ダブル", "double", "double"],
  ["たほう", "他方", "On the other hand"],
  ["たぼう", "多忙", "Busy"],
  ["たまう", "給う", "Give"],
  ["たましい", "魂", "soul"],
  ["たまり", "溜り", "Accumulation"],
  ["たまわる", "賜る", "Grant"],
  ["たもつ", "保つ", "keep"],
  ["たやすい", "容易い", "easy"],
  ["たよう", "多様", "Diverse"],
  ["だるい", "だるい", "Tired"],
  ["たるみ", "弛み", "slack"],
  ["たるむ", "弛む", "Loosen"],
  ["たれる", "垂れる", "Drooping"],
  ["タレント", "talent", "talent"],
  ["タワー", "tower", "tower"],
  ["たん", "単〜", "single~"],
  ["たんいつ", "単一", "single"],
  ["たんか", "短歌", "tanka"],
  ["たんか", "担架", "stretcher"],
  ["たんき", "短気", "Short temper"],
  ["だんけつ", "団結", "Unity"],
  ["たんけん", "探検", "expedition"],
  ["だんげん", "断言", "Affirmation"],
  ["たんしゅく", "短縮", "shortening"],
  ["だんぜん", "断然", "Definitely"],
  ["たんそ", "炭素", "carbon"],
  ["たんだい", "短大", "Junior College"],
  ["たんちょう", "単調", "Monotony"],
  ["たんどく", "単独", "Single"],
  ["だんな", "旦那", "husband"],
  ["たんぱ", "短波", "Shortwave"],
  ["たんぱくしつ", "蛋白質", "protein"],
  ["ダンプ", "dump", "dump"],
  ["だんめん", "断面", "cross section"],
  ["だんりょく", "弾力", "elasticity"],
  ["ちあん", "治安", "Public Safety"],
  ["チームワーク", "teamwork", "teamwork"],
  ["チェンジ", "change", "change"],
  ["ちがえる", "違える", "Different"],
  ["ちくさん", "畜産", "Animal Husbandry"],
  ["ちくしょう", "畜生", "damn it"],
  ["ちくせき", "蓄積", "Accumulation"],
  ["ちけい", "地形", "terrain"],
  ["ちせい", "知性", "intelligence"],
  ["ちち", "乳", "milk"],
  ["ちぢまる", "縮まる", "Shrink"],
  ["ちつじょ", "秩序", "order"],
  ["ちっそく", "窒息", "Suffocation"],
  ["ちてき", "知的", "intellectual"],
  ["チャイム", "chime", "chime"],
  ["ちゃくしゅ", "着手", "Get started"],
  ["ちゃくしょく", "着色", "Coloring"],
  ["ちゃくせき", "着席", "Seated"],
  ["ちゃくもく", "着目", "Focus"],
  ["ちゃくりく", "着陸", "landing"],
  ["ちゃっこう", "着工", "Start of construction"],
  ["ちゃのま", "茶の間", "Living room"],
  ["ちゃのゆ", "茶の湯", "tea ceremony"],
  ["ちやほや", "ちやほや", "Pampered"],
  ["チャンネル", "channel", "channel"],
  ["ちゅうがえり", "宙返り", "somersault"],
  ["ちゅうけい", "中継", "relay"],
  ["ちゅうこく", "忠告", "advice"],
  ["ちゅうじつ", "忠実", "Loyal"],
  ["ちゅうしょう", "中傷", "Defamation"],
  ["ちゅうすう", "中枢", "Central"],
  ["ちゅうせん", "抽選", "lottery"],
  ["ちゅうだん", "中断", "Suspended"],
  ["ちゅうどく", "中毒", "Poisoning"],
  ["ちゅうふく", "中腹", "Mid-slope"],
  ["ちゅうりつ", "中立", "Neutral"],
  ["ちゅうわ", "中和", "Neutralization"],
  ["ちょ", "〜著", "Written by"],
  ["ちょう", "腸", "Intestine"],
  ["ちょう", "蝶", "butterfly"],
  ["ちょう〜", "超〜", "Super~"],
  ["ちょういん", "調印", "Signed"],
  ["ちょうかく", "聴覚", "Hearing"],
  ["ちょうかん", "長官", "Commissioner"],
  ["ちょうこう", "聴講", "Attending"],
  ["ちょうしゅう", "徴収", "Collection"],
  ["ちょうしんき", "聴診器", "Stethoscope"],
  ["ちょうせん", "挑戦", "challenge"],
  ["ちょうてい", "調停", "arbitration"],
  ["ちょうふく", "重複", "Duplicates"],
  ["ちょうへん", "長編", "Long story"],
  ["ちょうほう", "重宝", "Useful"],
  ["ちょうり", "調理", "cooking"],
  ["ちょうわ", "調和", "Harmony"],
  ["ちょくぜん", "直前", "Last minute"],
  ["ちょくちょく", "ちょくちょく", "often"],
  ["ちょくめん", "直面", "Face"],
  ["ちょしょ", "著書", "book"],
  ["ちょちく", "貯蓄", "savings"],
  ["ちょっかん", "直感", "Intuition"],
  ["ちょっけい", "直径", "diameter"],
  ["ちょめい", "著名", "Famous"],
  ["ちらっと", "ちらっと", "A glimpse"],
  ["ちり", "塵", "dust"],
  ["ちりとり", "塵取", "Dust collector"],
  ["ちりょう", "治療", "Treatment"],
  ["ちんぎん", "賃金", "wages"],
  ["ちんでん", "沈澱", "precipitation"],
  ["ちんぼつ", "沈没", "Sinking"],
  ["ちんもく", "沈黙", "silence"],
  ["ちんれつ", "陳列", "display"],
  ["ついきゅう", "追及", "Pursuit"],
  ["ついせき", "追跡", "Tracking"],
  ["ついほう", "追放", "Banishment"],
  ["ついやす", "費やす", "spend"],
  ["ついらく", "墜落", "Crash"],
  ["つうかん", "痛感", "keen sense"],
  ["つうじょう", "通常", "usually"],
  ["つうせつ", "痛切", "Painful"],
  ["つえ", "杖", "Cane"],
  ["つかいみち", "使い道", "use"],
  ["つかえる", "仕える", "serve"],
  ["つかさどる", "司る", "govern"],
  ["つかのま", "束の間", "For a moment"],
  ["つきなみ", "月並", "Commonplace"],
  ["つぎめ", "継ぎ目", "Seams"],
  ["つきる", "尽きる", "run out"],
  ["つぐ", "接ぐ", "Connect"],
  ["つぐ", "継ぐ", "Succeed"],
  ["つくす", "尽す", "Do your best"],
  ["つくずく", "熟々", "Mature"],
  ["つぐない", "償い", "Atonement"],
  ["つくり", "作り／造り", "Making/Making"],
  ["つくろう", "繕う", "Mending"],
  ["つけくわえる", "付け加える", "add"],
  ["つげる", "告げる", "Tell"],
  ["つじつま", "辻褄「話の〜」", "Inconsistency &quot;The story of...&quot;"],
  ["つつ", "筒", "Cylinder"],
  ["つつく／つっつく", "突付く", "Poke"],
  ["つつしむ", "謹む", "Respectfully"],
  ["つっぱる", "突っ張る", "Be stubborn"],
  ["つとまる", "務まる", "Serve"],
  ["つとめさき", "勤め先", "Employer"],
  ["つとめて", "努めて", "Try your best"],
  ["つなみ", "津波", "tsunami"],
  ["つの", "角", "corner"],
  ["つのる", "募る", "Recruit"],
  ["つばさ", "翼", "Wings"],
  ["つぶやく", "呟く", "Tweet"],
  ["つぶら", "円らな", "Round"],
  ["つぶる", "瞑る『目を』〜", "Close your eyes ~"],
  ["つぼ", "壷", "pot"],
  ["つぼみ", "蕾", "bud"],
  ["つまむ", "摘む", "Pick"],
  ["つむ", "摘む", "Pick"],
  ["つゆ", "露", "Dew"],
  ["つよまる", "強まる", "Intensify"],
  ["つよめる", "強める", "ramp up"],
  ["つらなる", "連なる", "Connected"],
  ["つらぬく", "貫く", "pierce"],
  ["つらねる", "連ねる", "Link"],
  ["つりがね", "釣鐘", "Bell"],
  ["つりかわ", "吊革", "Strap"],
  ["てあて", "手当て", "allowance"],
  ["ていぎ", "定義", "Definition"],
  ["ていきょう", "提供", "Offering"],
  ["ていけい", "提携", "Partnership"],
  ["ていさい", "体裁", "Appearance"],
  ["ていじ", "提示", "Presentation"],
  ["ていしょく", "定食", "Set meal"],
  ["ていせい", "訂正", "correction"],
  ["ていたい", "停滞", "Stagnation"],
  ["ていたく", "邸宅", "Mansion"],
  ["ティッシュ（ペーパー）", "tissue paper", "tissue paper"],
  ["ていねん", "定年", "Retirement age"],
  ["ていぼう", "堤防", "Embankment"],
  ["ていり", "定理", "theorem"],
  ["データ", "data", "data"],
  ["ておくれ", "手遅れ", "Too late"],
  ["でかい", "でかい", "huge"],
  ["てがかり", "手掛かり", "clue"],
  ["てがける", "手掛ける", "Handling"],
  ["てかず", "手数", "Effort"],
  ["てがる", "手軽", "Easy"],
  ["てきおう", "適応", "Indications"],
  ["てきぎ", "適宜", "As appropriate"],
  ["てきせい", "適性", "Aptitude"],
  ["できもの", "出来物", "Product"],
  ["てぎわ", "手際", "Efficiency"],
  ["デコレーション", "decoration", "decoration"],
  ["てごろ", "手頃", "Affordable"],
  ["デザート", "dessert", "dessert"],
  ["デザイン", "design", "design"],
  ["てじゅん", "手順", "procedure"],
  ["てじょう", "手錠", "handcuffs"],
  ["てすう", "手数", "Effort"],
  ["てぢか", "手近", "Close at hand"],
  ["てっきり", "てっきり", "Surely"],
  ["てっこう", "鉄鋼", "Steel"],
  ["デッサン", "dessin", "design"],
  ["てっする", "徹する", "Be thorough"],
  ["てっぺん", "天辺", "top"],
  ["てつぼう", "鉄棒", "Horizontal bar"],
  ["でなおし", "出直し", "Start again"],
  ["てのひら", "掌", "palm"],
  ["てはい", "手配", "Arrangements"],
  ["てはず", "手筈", "Arrangements"],
  ["てびき", "手引き", "guidance"],
  ["てほん", "手本", "Example"],
  ["てまわし", "手回し", "Hand crank"],
  ["デモンストレーション", "demonstration", "demonstration"],
  ["てりかえす", "照り返す", "Reflect"],
  ["テレックス", "telex", "Telex"],
  ["てわけ", "手分け", "Division of labor"],
  ["てん", "天", "Heaven"],
  ["でんえん", "田園", "Countryside"],
  ["てんか", "点火", "ignition"],
  ["てんか", "天下", "World"],
  ["てんかい", "転回", "Turning"],
  ["てんかん", "転換", "Conversion"],
  ["てんきょ", "転居", "Relocation"],
  ["てんきん", "転勤", "transfer"],
  ["てんけん", "点検", "Inspection"],
  ["でんげん", "電源", "power supply"],
  ["てんこう", "転校", "Transfer"],
  ["てんごく", "天国", "heaven"],
  ["てんさい", "天才", "genius"],
  ["てんさい", "天災", "Natural disaster"],
  ["てんじ", "展示", "Exhibition"],
  ["てんじる・ずる,転じる・転ずる", "To turn", " to change"],
  ["でんせつ", "伝説", "Legend"],
  ["てんせん", "点線", "dotted line"],
  ["てんたい", "天体", "Celestial Bodies"],
  ["でんたつ", "伝達", "Transmission"],
  ["てんち", "天地", "Heaven and Earth"],
  ["てんで", "てんで", "In general"],
  ["てんにん", "転任", "Transfer"],
  ["てんぼう", "展望", "Outlook"],
  ["でんらい", "伝来", "Introduction"],
  ["てんらく", "転落", "Fall"],
  ["と", "と", "and"],
  ["といあわせる", "問合せる", "Inquire"],
  ["とう", "棟", "Building"],
  ["とう〜", "当〜", "Right~"],
  ["どうい", "同意", "Consent"],
  ["どういん", "動員", "mobilization"],
  ["どうかん", "同感", "I agree"],
  ["とうき", "陶器", "Pottery"],
  ["とうぎ", "討議", "discussion"],
  ["どうき", "動機", "Motivation"],
  ["とうきゅう", "等級", "grade"],
  ["どうきゅう", "同級", "Classmates"],
  ["どうきょ", "同居", "Living together"],
  ["とうこう", "登校", "Going to school"],
  ["とうごう", "統合", "Integration"],
  ["どうこう", "動向", "Trends"],
  ["とうさん", "倒産", "bankruptcy"],
  ["とうし", "投資", "investment"],
  ["どうし", "同志", "Comrades"],
  ["どうし", "同士", "Peer"],
  ["どうじょう", "同情", "sympathy"],
  ["どうじょう", "道場", "dojo"],
  ["とうせい", "統制", "control"],
  ["とうせん", "当選", "Winner"],
  ["とうそう", "逃走", "Escape"],
  ["とうそつ", "統率", "Leadership"],
  ["とうたつ", "到達", "Reach"],
  ["とうち", "統治", "Governance"],
  ["どうちょう", "同調", "tuning"],
  ["とうてい", "到底", "Absolutely"],
  ["どうてき", "動的", "dynamic"],
  ["とうとい,尊い・貴い", "Precious", " precious"],
  ["どうとう", "同等", "Equivalent"],
  ["どうどう", "堂々", "Proud"],
  ["とうとぶ", "尊ぶ", "Respect"],
  ["とうにゅう", "投入", "Input"],
  ["どうにゅう", "導入", "introduction"],
  ["どうふう", "同封", "Enclosed"],
  ["とうぼう", "逃亡", "Escape"],
  ["とうみん", "冬眠", "hibernation"],
  ["どうめい", "同盟", "alliance"],
  ["どうやら", "どうやら", "Apparently"],
  ["どうよう", "動揺", "upset"],
  ["どうりょく", "動力", "power"],
  ["とうろく", "登録", "Registration"],
  ["とうろん", "討論", "Discussion"],
  ["とおざかる", "遠ざかる", "Move away"],
  ["とおまわり", "遠回り", "Detour"],
  ["トーン", "tone", "tone"],
  ["とかく", "兎角", "Anyway"],
  ["とがめる", "咎める", "blame"],
  ["ときおり", "時折", "sometimes"],
  ["とぎれる", "とぎれる", "Interrupted"],
  ["とく", "説く", "preach"],
  ["とぐ", "研ぐ", "sharpen"],
  ["とくぎ", "特技", "Special Skills"],
  ["どくさい", "独裁", "Dictatorship"],
  ["とくさん", "特産", "Specialty"],
  ["どくじ", "独自", "Unique"],
  ["どくしゃ", "読者", "reader"],
  ["とくしゅう", "特集", "Featured"],
  ["どくせん", "独占", "Exclusive"],
  ["どくそう", "独創", "Originality"],
  ["とくてん", "得点", "score"],
  ["とくは", "特派", "Special Agent"],
  ["とくゆう", "特有", "Unique"],
  ["とげ", "棘「〜を刺す」", "Thorn &quot;to stab&quot;"],
  ["とげる", "遂げる", "Accomplish"],
  ["どころか", "〜所か", "~ or somewhere"],
  ["としごろ", "年頃", "Age"],
  ["とじまり", "戸締り", "Locking the door"],
  ["とじょう", "途上", "In progress"],
  ["とじる", "綴じる", "Bind"],
  ["どだい", "土台", "Foundation"],
  ["とだえる", "途絶える", "Discontinued"],
  ["とっきょ", "特許", "Patents"],
  ["とっけん", "特権", "Privileges"],
  ["とっさ・に", "咄嗟・に", "Suddenly"],
  ["とつじょ", "突如", "suddenly"],
  ["とっぱ", "突破", "Breakthrough"],
  ["どて", "土手", "bank"],
  ["とどけ", "届け", "Delivery"],
  ["とどこおる", "滞る", "be delayed"],
  ["ととのえる", "整える", "Arrange"],
  ["とどめる", "止める", "stop"],
  ["となえる", "唱える", "recite"],
  ["とのさま", "殿様", "Lord"],
  ["どひょう", "土俵", "Sumo ring"],
  ["とびら", "扉", "door"],
  ["どぶ", "溝", "groove"],
  ["とほ", "徒歩", "Walking"],
  ["どぼく", "土木", "Civil Engineering"],
  ["とぼける", "惚ける", "fall in love"],
  ["とぼしい", "乏しい", "poor"],
  ["とまどい", "戸惑い", "Confusion"],
  ["とも,共", "Co.", " Ltd."],
  ["ともかせぎ", "共稼ぎ", "Double income"],
  ["ともばたらき", "共働き", "Dual income"],
  ["ドライ", "dry", "dry"],
  ["ドライクリーニング", "dry cleaning", "Dry cleaning"],
  ["ドライバー", "driver", "Driver"],
  ["ドライブイン", "drive-in", "Drive-in"],
  ["トラブル", "trouble", "trouble"],
  ["トランジスター", "transistor", "transistor"],
  ["とりあえず", "取り敢えず", "For now"],
  ["とりあつかい", "取り扱い", "handling"],
  ["とりあつかう", "取り扱う", "handle"],
  ["とりい", "鳥居", "torii"],
  ["とりかえ", "取り替え", "Replacement"],
  ["とりくむ", "取り組む", "work on"],
  ["とりしまり", "取り締まり", "control"],
  ["とりしまる", "取り締まる", "Crackdown"],
  ["とりしらべる", "取り調べる", "Investigate"],
  ["とりたてる", "取り立てる", "Collect"],
  ["とりつぐ", "取り次ぐ", "Transfer"],
  ["とりつける", "取り付ける", "Install"],
  ["とりのぞく", "取り除く", "remove"],
  ["とりひき", "取り引き", "transaction"],
  ["とりまく", "取り巻く", "Surrounding"],
  ["とりまぜる", "取り混ぜる", "Mix"],
  ["とりもどす", "取り戻す", "regain"],
  ["とりよせる", "取り寄せる", "order"],
  ["ドリル", "drill", "drill"],
  ["とりわけ", "取り分け", "Serve"],
  ["とろける", "蕩ける", "Melt"],
  ["どわすれ", "度忘れ", "Forgotten"],
  ["どんかん", "鈍感", "Insensitive"],
  ["とんだ", "とんだ", "What a disaster!"],
  ["とんや", "問屋", "Wholesaler"],
  ["ないかく", "内閣", "Cabinet"],
  ["ないし", "乃至", "or"],
  ["ないしょ", "内緒", "secret"],
  ["ないしん", "内心", "Inner thoughts"],
  ["ないぞう", "内臓", "Viscera"],
  ["ナイター", "night+er", "night+er"],
  ["ないぶ", "内部", "internal"],
  ["ないらん", "内乱", "Civil War"],
  ["ないりく", "内陸", "inland"],
  ["なえ", "苗", "Seedlings"],
  ["なおさら", "尚更", "Even more so"],
  ["ながし", "流し", "Sink"],
  ["ながなが", "長長", "long"],
  ["なかほど", "中程", "middle"],
  ["なぎさ", "渚", "Beach"],
  ["なげく", "嘆く", "Lament"],
  ["なげだす", "投げ出す", "Throw out"],
  ["なこうど", "仲人", "matchmaker"],
  ["なごやか", "和やか", "Peaceful"],
  ["なごり", "名残", "Remains"],
  ["なさけ", "情け", "Compassion"],
  ["なさけない", "情け無い", "Pathetic"],
  ["なさけぶかい", "情け深い", "compassionate"],
  ["なじる", "詰る", "Clogged"],
  ["なだかい", "名高い", "Famous"],
  ["なだれ", "雪崩", "avalanche"],
  ["なつく", "懐く", "Become attached to"],
  ["なづける", "名付ける", "Name"],
  ["なにげない", "何気ない", "casual"],
  ["なにとぞ", "何卒", "kindly"],
  ["なにより", "何より", "Above all"],
  ["ナプキン", "napkin", "napkin"],
  ["なふだ", "名札", "Name tag"],
  ["なまぐさい", "生臭い", "Fishy"],
  ["なまぬるい", "生温い", "lukewarm"],
  ["なまみ", "生身", "Living flesh"],
  ["なまり", "鉛", "lead"],
  ["なみ", "並", "Normal"],
  ["なめらか", "滑らか", "Smooth"],
  ["なめる", "嘗める", "lick"],
  ["なやましい", "悩ましい", "troublesome"],
  ["なやます", "悩ます", "Worry"],
  ["なやみ", "悩み", "worries"],
  ["ならす", "慣らす／馴らす", "to get used to/accustom"],
  ["ならびに", "並びに", "and"],
  ["なりたつ", "成り立つ", "It holds true"],
  ["なるたけ", "成る丈", "As much as possible"],
  ["なれ", "慣れ", "Habit"],
  ["なれなれしい", "馴れ馴れしい", "Familiar"],
  ["なん", "難", "Difficult"],
  ["なんか", "〜なんか", "~Something"],
  ["ナンセンス", "nonsense", "nonsense"],
  ["なんだか", "何だか", "somehow"],
  ["なんだかんだ", "なんだかんだ", "Somehow"],
  ["なんと", "何と", "What"],
  ["なんなり", "何なり", "What is it?"],
  ["に", "荷", "load"],
  ["にかよう", "似通う", "Similar"],
  ["にきび", "靤", "hail"],
  ["にぎわう", "賑わう", "Bustling"],
  ["にくしみ", "憎しみ", "hatred"],
  ["にくしん", "肉親", "Immediate family"],
  ["にくたい", "肉体", "body"],
  ["にげだす", "逃げ出す", "Run away"],
  ["にしび", "西日", "Afternoon sun"],
  ["にじむ", "滲む", "Oozing"],
  ["にせもの", "贋物", "fake"],
  ["にちや", "日夜", "day and night"],
  ["にづくり", "荷造", "Packing"],
  ["になう", "担う", "bear"],
  ["にぶる", "鈍る", "become dull"],
  ["にもかかわらず", "にも-拘らず", "Despite"],
  ["ニュアンス", "nuance", "News"],
  ["ニュー", "new", "new"],
  ["にゅうしゅ", "入手", "Obtaining"],
  ["にゅうしょう", "入賞", "Winners"],
  ["にゅうよく", "入浴", "bathing"],
  ["にょう", "尿", "urine"],
  ["にんしき", "認識", "recognition"],
  ["にんじょう", "人情", "humanity"],
  ["にんしん", "妊娠", "pregnancy"],
  ["にんむ", "任務", "mission"],
  ["にんめい", "任命", "Appointment"],
  ["ぬかす", "抜かす", "Skip"],
  ["ぬけだす", "抜け出す", "Get out"],
  ["ぬし", "主", "main"],
  ["ぬすみ", "盗み", "steal"],
  ["ぬま", "沼", "Swamp"],
  ["ね", "音", "sound"],
  ["ねいろ", "音色", "Sound quality"],
  ["ねうち", "値打ち", "value"],
  ["ネガ", "negative", "negative"],
  ["ねかせる", "寝かせる", "Let it rest"],
  ["ねじまわし", "螺旋回し", "Spiral rotation"],
  ["ねじれる", "捩れる", "Twist"],
  ["ねたむ", "妬む", "Jealous"],
  ["ねだる", "強請る", "tease"],
  ["ねつい", "熱意", "enthusiasm"],
  ["ねっとう", "熱湯", "Boiling water"],
  ["ねつりょう", "熱量", "Heat"],
  ["ねばり", "粘り", "Tenacity"],
  ["ねばる", "粘る", "Persist"],
  ["ねまわし", "根回し", "Laying the groundwork"],
  ["ねる", "練る", "Knead"],
  ["ねん", "念", "Mind"],
  ["ねんが", "年賀", "New Year"],
  ["ねんかん", "年鑑", "Yearbook"],
  ["ねんがん", "念願", "Long-cherished wish"],
  ["ねんごう", "年号", "Year"],
  ["ねんしょう", "燃焼", "combustion"],
  ["ねんちょう", "年長", "Senior"],
  ["ねんりょう", "燃料", "fuel"],
  ["ねんりん", "年輪", "Tree Rings"],
  ["ノイローゼ", "Neurose", "Neurose"],
  ["のう", "脳", "brain"],
  ["のうこう", "農耕", "Agriculture"],
  ["のうじょう", "農場", "farm"],
  ["のうち", "農地", "Farmland"],
  ["のうにゅう", "納入", "Delivery"],
  ["のがす", "逃す", "Escape"],
  ["のがれる", "逃れる", "escape"],
  ["のきなみ", "軒並", "All across the street"],
  ["のぞましい", "望ましい", "desirable"],
  ["のぞむ", "臨む", "to attend"],
  ["のっとる", "乗っ取る", "Take over"],
  ["のどか", "長閑", "Peaceful"],
  ["ののしる", "罵る", "insult"],
  ["のべ", "延べ", "total"],
  ["のみこむ", "飲み込む", "swallow"],
  ["のりこむ", "乗り込む", "Get on"],
  ["は", "刃", "blade"],
  ["は", "〜派", "~ faction"],
  ["バー", "bar", "bar"],
  ["はあく", "把握", "Grasp"],
  ["はい", "肺", "lung"],
  ["はい", "〜敗", "~ Defeat"],
  ["はいき", "廃棄", "Discard"],
  ["はいきゅう", "配給", "distribution"],
  ["ばいきん", "黴菌", "Mold"],
  ["はいぐうしゃ", "配偶者", "Spouse"],
  ["はいけい", "背景", "background"],
  ["はいけい,拝啓", "Dear Sir/Madam", ""],
  ["はいご", "背後", "Behind"],
  ["はいし", "廃止", "abolition"],
  ["はいしゃく", "拝借", "Borrowed"],
  ["はいじょ", "排除", "Exclusion"],
  ["ばいしょう", "賠償", "compensation"],
  ["はいすい", "排水", "Drainage"],
  ["はいせん", "敗戦", "defeat"],
  ["はいち", "配置", "Placement"],
  ["はいふ", "配布", "distribution"],
  ["はいぶん", "配分", "Allocation"],
  ["はいぼく", "敗北", "defeat"],
  ["ばいりつ", "倍率", "magnification"],
  ["はいりょ", "配慮", "consideration"],
  ["はいれつ", "配列", "array"],
  ["はえる", "映える", "shine"],
  ["はかい", "破壊", "destruction"],
  ["はかどる", "捗る", "Progress"],
  ["はかない", "儚い", "ephemeral"],
  ["ばかばかしい", "馬鹿馬鹿しい", "Ridiculous"],
  ["はかる", "諮る", "consult"],
  ["はかる", "図る", "Plan"],
  ["はき", "破棄", "destruction"],
  ["はぐ", "剥ぐ", "Peel"],
  ["はくがい", "迫害", "persecution"],
  ["はくじゃく", "薄弱", "Weak"],
  ["はくじょう", "白状", "Confession"],
  ["ばくぜん", "漠然", "vague"],
  ["ばくだん", "爆弾", "bomb"],
  ["ばくは", "爆破", "Explosion"],
  ["ばくろ", "暴露", "exposure"],
  ["はげます", "励ます", "encourage"],
  ["はげむ", "励む", "Encourage"],
  ["はげる", "剥げる", "Peel off"],
  ["ばける", "化ける", "Disguise"],
  ["はけん", "派遣", "Dispatch"],
  ["はじ", "恥", "shame"],
  ["はじく", "弾く", "play"],
  ["パジャマ", "pajamas", "Pajamas"],
  ["はじらう", "恥らう", "Embarrassed"],
  ["はじる", "恥じる", "Embarrassed"],
  ["はしわたし", "橋渡し", "Bridging the gap"],
  ["バス", "bath", "bath"],
  ["はずむ", "弾む", "Bounce"],
  ["はそん", "破損", "Damage"],
  ["はたく", "叩く", "Hit"],
  ["はだし", "裸足", "barefoot"],
  ["はたす", "果たす", "Fulfill"],
  ["はちみつ", "蜂蜜", "honey"],
  ["パチンコ", "pachinko", "Pachinko"],
  ["ばつ", "罰", "punishment"],
  ["はついく", "発育", "development"],
  ["はつが", "発芽", "Germination"],
  ["はつげん", "発言", "comment"],
  ["バッジ", "badge", "badge"],
  ["はっせい", "発生", "occurrence"],
  ["バッテリー", "battery", "battery"],
  ["バット", "bat", "bat"],
  ["はつびょう", "発病", "Onset of the disease"],
  ["はつみみ", "初耳", "First time hearing about it"],
  ["はて", "果", "Fruit"],
  ["はてる", "果てる", "End"],
  ["ばてる", "ばてる", "Bater"],
  ["パトカー", "patrol car", "Patrol car"],
  ["はなはだ", "甚だ", "very"],
  ["はなばなしい", "華々しい", "spectacular"],
  ["はなびら", "花弁", "petal"],
  ["はなやか", "華やか", "Gorgeous"],
  ["はばむ", "阻む", "Block"],
  ["はま", "浜", "beach"],
  ["はまべ", "浜辺", "beach"],
  ["はまる", "嵌る", "fit"],
  ["はやす", "生やす", "grow"],
  ["はやめる", "早める", "Speed up"],
  ["はらだつ", "腹立つ", "Angry"],
  ["はらっぱ", "原っぱ", "Meadow"],
  ["はらはら", "はらはら", "Anxious"],
  ["ばらまく", "散撒く", "Scatter"],
  ["はりがみ", "張り紙", "Notice"],
  ["はるか", "遥か", "far"],
  ["はれつ", "破裂", "rupture"],
  ["はれる", "腫れる", "Swelling"],
  ["はん", "判", "Judgment"],
  ["はん", "版", "Edition"],
  ["はん", "班", "Group"],
  ["はんえい", "繁栄", "prosperity"],
  ["はんが", "版画", "Prints"],
  ["ハンガー", "hanger", "hanger"],
  ["はんかん", "反感", "Antipathy"],
  ["はんきょう", "反響", "Response"],
  ["パンク", "puncture/punk", "puncture/punk"],
  ["はんげき", "反撃", "Counterattack"],
  ["はんけつ", "判決", "Verdict"],
  ["はんしゃ", "反射", "Reflection"],
  ["はんじょう", "繁盛", "Thriving"],
  ["はんしょく", "繁殖", "Breeding"],
  ["はんてい", "判定", "judgement"],
  ["ばんにん", "万人", "Million people"],
  ["ばんねん", "晩年", "Later years"],
  ["はんのう", "反応", "reaction"],
  ["ばんのう", "万能", "Versatile"],
  ["はんぱ", "半端", "Half"],
  ["はんぱつ", "反発", "Repulsion"],
  ["はんらん", "反乱", "Rebellion"],
  ["はんらん", "氾濫", "flood"],
  ["ひ", "碑", "Monument"],
  ["ひ", "被", "Damaged"],
  ["び", "美", "Beauty"],
  ["ひいては", "延いては", "By extension"],
  ["ひかえしつ", "控室", "waiting room"],
  ["ひかえる", "控える", "Refrain"],
  ["ひかげ", "日陰", "Shade"],
  ["ひかん", "悲観", "Pessimistic"],
  ["ひきあげる", "引き上げる", "Pull up"],
  ["ひきいる", "率いる", "Lead"],
  ["ひきおこす", "引き起こす", "cause"],
  ["ひきさげる", "引き下げる", "reduce"],
  ["ひきずる", "引き摺る", "drag"],
  ["ひきとる", "引き取る", "Pick up"],
  ["ひけつ", "否決", "Rejected"],
  ["ひこう", "非行", "delinquency"],
  ["ひごろ", "日頃", "Everyday"],
  ["ひさしい", "久しい", "long"],
  ["ひさん", "悲惨", "Tragic"],
  ["ビジネス", "business", "business"],
  ["ひじゅう", "比重", "specific gravity"],
  ["びじゅつ", "美術", "art"],
  ["ひしょ", "秘書", "Secretary"],
  ["びしょう", "微笑", "smile"],
  ["ひずむ", "歪む", "distort"],
  ["ひそか", "密か", "Secret"],
  ["ひたす", "浸す", "Immerse"],
  ["ひたすら", "只管", "Just"],
  ["ひだりきき", "左利き", "left handed"],
  ["ひっかく", "引っ掻く", "scratch"],
  ["ひっしゅう", "必修", "Required Courses"],
  ["びっしょり", "びっしょり", "drenched"],
  ["ひつぜん", "必然", "Inevitably"],
  ["ひってき", "匹敵", "Rival"],
  ["ひといき", "一息", "breath"],
  ["ひとかげ", "人影", "Figure"],
  ["ひとがら", "人柄", "personality"],
  ["ひとけ", "人気", "Popularity"],
  ["ひところ", "一頃", "At one time"],
  ["ひとじち", "人質", "hostage"],
  ["ひとすじ", "一筋", "Single-minded"],
  ["ひとめ", "人目", "Number of people"],
  ["ひどり", "日取り", "Date"],
  ["ひな", "雛", "chick"],
  ["ひなた", "日向", "Hinata"],
  ["ひなまつり", "雛祭り", "Doll&#39;s Festival"],
  ["ひなん", "非難", "Condemnation"],
  ["ひなん", "避難", "Evacuation"],
  ["ひのまる", "日の丸", "Japanese flag"],
  ["ひばな", "火花", "spark"],
  ["ひび", "皹「かべの〜」", "Akira &quot;On the wall&quot;"],
  ["ひめい", "悲鳴", "scream"],
  ["ひやかす", "冷やかす", "Make fun of"],
  ["ひやけ", "日焼け", "Sunburn"],
  ["ひょう", "票", "Vote"],
  ["ひょうご", "標語", "Slogan"],
  ["びょうしゃ", "描写", "Description"],
  ["ひょっと", "ひょっと", "By chance"],
  ["びら", "びら", "Villa"],
  ["ひらたい", "平たい", "flat"],
  ["びり", "びり", "Last"],
  ["ひりつ", "比率", "ratio"],
  ["ひりょう", "肥料", "fertilizer"],
  ["びりょう", "微量", "Trace"],
  ["ひるめし", "昼飯", "lunch"],
  ["ひれい", "比例", "Proportional"],
  ["ひろう", "疲労", "fatigue"],
  ["ひろう", "披露", "Show"],
  ["ひろまる", "広まる", "spread"],
  ["びんかん", "敏感", "sensitive"],
  ["ひんけつ", "貧血", "anemia"],
  ["ひんこん", "貧困", "poverty"],
  ["ひんしつ", "品質", "quality"],
  ["ひんじゃく", "貧弱", "poor"],
  ["ひんしゅ", "品種", "Varieties"],
  ["ヒント", "hint", "hint"],
  ["ひんぱん", "頻繁", "Frequently"],
  ["びんぼう", "貧乏", "poor"],
  ["ファイト", "fight", "fight"],
  ["ファイル", "file", "file"],
  ["ファン", "fan", "Fan"],
  ["ふい", "不意", "Unexpectedly"],
  ["フィルター", "filter", "filter"],
  ["ふう", "封", "Seal"],
  ["ふうさ", "封鎖", "Blockade"],
  ["ふうしゃ", "風車", "Windmill"],
  ["ふうしゅう", "風習", "Customs"],
  ["ふうぞく", "風俗", "Entertainment"],
  ["ブーツ", "boots", "boots"],
  ["ふうど", "風土", "Climate"],
  ["ブーム", "boom", "boom"],
  ["フェリー", "ferry boat", "Ferry Boat"],
  ["フォーム", "form", "form"],
  ["ぶか", "部下", "subordinate"],
  ["ふかけつ", "不可欠", "essential"],
  ["ぶかぶか", "ぶかぶか", "too big"],
  ["ふかめる", "深める", "deepen"],
  ["ふきつ", "不吉", "Ominous"],
  ["ふきょう", "不況", "recession"],
  ["ふきん", "布巾", "Dishcloth"],
  ["ふく", "福", "Fortune"],
  ["ふくごう", "複合", "composite"],
  ["ふくし", "福祉", "welfare"],
  ["ふくめん", "覆面", "Masked"],
  ["ふくれる", "膨れる", "swell"],
  ["ふけいき", "不景気", "Recession"],
  ["ふける", "老ける", "age"],
  ["ふける", "耽る", "Indulge"],
  ["ふごう", "富豪", "Wealthy"],
  ["ふこく", "布告", "Proclamation"],
  ["ブザー", "buzzer", "buzzer"],
  ["ふさい", "負債", "liabilities"],
  ["ふざい", "不在", "absence"],
  ["ふさわしい", "相応しい", "suitable"],
  ["ふじゅん", "不順", "Irregular"],
  ["ふしょう", "負傷", "Injury"],
  ["ぶじょく", "侮辱", "insult"],
  ["ふしん", "不振", "Poor performance"],
  ["ふしん", "不審", "distrust"],
  ["ぶそう", "武装", "Armament"],
  ["ふだ", "札", "Bills"],
  ["ふちょう", "不調", "Illness"],
  ["ふっかつ", "復活", "revival"],
  ["ぶつぎ", "物議", "Controversy"],
  ["ふっきゅう", "復旧", "restoration"],
  ["ふっこう", "復興", "reconstruction"],
  ["ぶっし", "物資", "Supplies"],
  ["ぶつぞう", "仏像", "Buddhist statue"],
  ["ぶったい", "物体", "object"],
  ["ふっとう", "沸騰", "boiling"],
  ["ふとう", "不当", "Unfair"],
  ["ふどうさん", "不動産", "real estate"],
  ["ぶなん", "無難", "Safe"],
  ["ふにん", "赴任", "Assignment"],
  ["ふはい", "腐敗", "corruption"],
  ["ふひょう", "不評", "Unpopular"],
  ["ふふく", "不服", "Dissatisfaction"],
  ["ふへん", "普遍", "Universal"],
  ["ふまえる", "踏まえる", "Take into account"],
  ["ふみこむ", "踏み込む", "Step in"],
  ["ふめい", "不明", "not clear"],
  ["ぶもん", "部門", "Department"],
  ["ふよう", "扶養", "Dependent"],
  ["ふらふら", "ふらふら", "Unsteady"],
  ["ぶらぶら", "ぶらぶら", "Wandering around"],
  ["ふり", "振り", "Swing"],
  ["ふりかえる", "振り返る", "look back"],
  ["ふりだし", "振り出し", "Starting Over"],
  ["ふりょう", "不良", "Bad"],
  ["ふりょく", "浮力", "buoyancy"],
  ["ぶりょく", "武力", "Military force"],
  ["ブルー", "blue", "blue"],
  ["ふるわせる", "震わせる", "Shake"],
  ["ぶれい", "無礼", "rude"],
  ["ふろく", "付録", "appendix"],
  ["フロント", "front", "front"],
  ["ふんがい", "憤慨", "Indignation"],
  ["ぶんかざい", "文化財", "Cultural Assets"],
  ["ぶんぎょう", "分業", "Division of labor"],
  ["ぶんご", "文語", "Literary language"],
  ["ぶんさん", "分散", "Dispersion"],
  ["ぶんし", "分子", "molecule"],
  ["ふんしつ", "紛失", "Lost"],
  ["ふんしゅつ", "噴出", "Eruption"],
  ["ぶんしょ", "文書", "document"],
  ["ふんそう", "紛争", "Conflict"],
  ["ふんだん", "ふんだん", "Abundant"],
  ["ぶんたん", "分担", "Sharing"],
  ["ふんとう", "奮闘", "Effort"],
  ["ぶんぱい", "分配", "Distribution"],
  ["ぶんぼ", "分母", "denominator"],
  ["ふんまつ", "粉末", "Powder"],
  ["ぶんり", "分離", "Separation"],
  ["ぶんれつ", "分裂", "division"],
  ["ペア", "pair", "pair"],
  ["へいき", "兵器", "weapons"],
  ["へいこう", "並行", "Concurrent"],
  ["へいこう", "閉口", "Closed"],
  ["へいさ", "閉鎖", "closure"],
  ["へいし", "兵士", "soldier"],
  ["へいじょう", "平常", "normal"],
  ["へいほう", "平方", "square"],
  ["へいれつ", "並列", "Parallel"],
  ["ベース", "base", "Base"],
  ["へきえき", "辟易", "Fed up"],
  ["ぺこぺこ", "ぺこぺこ", "Bow bow"],
  ["ベスト", "best/vest", "best/vest"],
  ["ベストセラー", "best-seller", "Best Seller"],
  ["へだたる", "隔たる", "Separate"],
  ["べっきょ", "別居", "Separation"],
  ["へり", "縁", "edge"],
  ["へりくだる", "謙る", "Be humble"],
  ["へる", "経る", "go through"],
  ["べんかい", "弁解", "excuse"],
  ["へんかく", "変革", "Transformation"],
  ["へんかん", "返還", "return"],
  ["べんぎ", "便宜", "convenience"],
  ["へんきゃく", "返却", "return"],
  ["へんけん", "偏見", "prejudice"],
  ["べんご", "弁護", "Defense"],
  ["へんさい", "返済", "repayment"],
  ["べんしょう", "弁償", "Compensation"],
  ["へんせん", "変遷", "Transition"],
  ["へんとう", "返答", "reply"],
  ["へんどう", "変動", "Variation"],
  ["べんろん", "弁論", "argument"],
  ["ほ", "穂", "Ear"],
  ["ほいく", "保育", "Childcare"],
  ["ボイコット", "boycott", "boycott"],
  ["ポイント", "point", "point"],
  ["ほうあん", "法案", "bill"],
  ["ぼうえい", "防衛", "defense"],
  ["ぼうか", "防火", "Fire prevention"],
  ["ほうかい", "崩壊", "Collapse"],
  ["ぼうがい", "妨害", "Disruption"],
  ["ほうがく", "法学", "Law"],
  ["ほうき", "放棄", "Abandonment"],
  ["ほうけん", "封建", "Feudal"],
  ["ほうさく", "方策", "Measures"],
  ["ほうさく", "豊作", "Good harvest"],
  ["ほうし", "奉仕", "service"],
  ["ほうしき", "方式", "method"],
  ["ほうしゃ", "放射", "radiation"],
  ["ほうしゃのう", "放射能", "radioactivity"],
  ["ほうしゅう", "報酬", "Rewards"],
  ["ほうしゅつ", "放出", "Emission"],
  ["ほうじる‧ずる", "報じる‧報ずる", "to report"],
  ["ぼうせき", "紡績", "spinning"],
  ["ぼうぜん,呆然、茫然", "Stunned", " dumbfounded"],
  ["ほうち", "放置", "Leave"],
  ["ぼうちょう", "膨張", "expansion"],
  ["ほうてい", "法廷", "Court"],
  ["ほうどう", "報道", "Press"],
  ["ぼうとう", "冒頭", "beginning"],
  ["ぼうどう", "暴動", "Riots"],
  ["ほうび", "褒美", "reward"],
  ["ぼうふう", "暴風", "Storm"],
  ["ほうむる", "葬る", "bury"],
  ["ほうりこむ", "放り込む", "Throw in"],
  ["ほうりだす", "放り出す", "Throw out"],
  ["ぼうりょく", "暴力", "violence"],
  ["ほうわ", "飽和", "Saturation"],
  ["ホース", "hoos", "hoos"],
  ["ポーズ", "pose/pause", "Pose/pause"],
  ["ホール", "hall/hole", "Hall/Hole"],
  ["ほおん", "保温", "Thermal insulation"],
  ["ほかく", "捕獲", "capture"],
  ["ほかん", "保管", "storage"],
  ["ほきゅう", "補給", "Resupply"],
  ["ほきょう", "補強", "Reinforcement"],
  ["ぼきん", "募金", "Donations"],
  ["ぼくし", "牧師", "Pastor"],
  ["ほげい", "捕鯨", "whaling"],
  ["ぼける", "惚ける、呆ける／暈ける", "to be confused"],
  ["ほけん", "保険", "insurance"],
  ["ほご", "保護", "protection"],
  ["ぼこう", "母校", "alma mater"],
  ["ぼこく", "母国", "Home Country"],
  ["ほこる", "誇る", "Be proud"],
  ["ほころびる", "綻びる", "begin to open"],
  ["ほし〜", "干し〜", "Drying~"],
  ["ポジション", "position", "position"],
  ["ほしもの", "干し物", "Dried goods"],
  ["ほしゅ", "保守", "Maintenance"],
  ["ほじゅう", "補充", "Replenishment"],
  ["ほじょ", "補助", "auxiliary"],
  ["ほしょう", "保障", "security"],
  ["ほしょう", "補償", "compensation"],
  ["ほそう", "舗装", "pavement"],
  ["ほそく", "補足", "supplement"],
  ["ぼち", "墓地", "Cemetery"],
  ["ほっさ", "発作", "Seizures"],
  ["ぼっしゅう", "没収", "Confiscation"],
  ["ほっそく", "発足", "Launch"],
  ["ほっと", "ほっと", "Relief"],
  ["ポット", "pot", "pot"],
  ["ほっぺた", "頬っぺた", "cheek"],
  ["ぼつぼつ", "ぼつぼつ", "Gradually"],
  ["ぼつらく", "没落", "downfall"],
  ["ほどける", "解ける", "Solvable"],
  ["ほどこす", "施す", "Carry out"],
  ["ほとり", "畔", "Bank"],
  ["ぼやく", "ぼやく", "Complain"],
  ["ぼやける", "暈やける", "Blurred"],
  ["ほよう", "保養", "Recreation"],
  ["ほりょ", "捕虜", "Prisoner"],
  ["ボルト", "volt/bolt", "volt/bolt"],
  ["ほろびる", "滅びる", "perish"],
  ["ほろぶ", "滅ぶ", "Perish"],
  ["ほろぼす", "滅ぼす", "destroy"],
  ["ほんかく", "本格", "Authentic"],
  ["ほんかん", "本館", "Main Building"],
  ["ほんき", "本気", "Serious"],
  ["ほんごく", "本国", "Home Country"],
  ["ほんしつ", "本質", "Essence"],
  ["ほんたい", "本体", "Main unit"],
  ["ほんね", "本音", "Real thoughts"],
  ["ほんのう", "本能", "instinct"],
  ["ほんば", "本場", "The real thing"],
  ["ポンプ", "pomp", "Pomp"],
  ["ほんぶん", "本文", "Body"],
  ["ほんみょう", "本名", "Real name"],
  ["マーク", "mark", "mark"],
  ["マイ〜", "my", "my"],
  ["マイクロフォン", "microphone", "microphone"],
  ["まいぞう", "埋蔵", "Buried"],
  ["まう", "舞う", "Dance"],
  ["まうえ", "真上", "Directly above"],
  ["まえうり", "前売り", "Advance ticket"],
  ["まえおき", "前置き", "Introduction"],
  ["まえもって", "前もって", "In advance"],
  ["まかす", "任す", "Leave it to"],
  ["まかす", "負かす", "Defeat"],
  ["まかなう", "賄う", "Cover"],
  ["まぎらわしい", "紛らわしい", "confusing"],
  ["まぎれる", "紛れる", "Blend in"],
  ["まく", "膜", "film"],
  ["まごころ", "真心", "Sincerity"],
  ["まごつく", "間誤つく", "Make a mistake"],
  ["まこと", "誠", "Sincerity"],
  ["まことに", "誠に", "Sincerely"],
  ["まさしく", "正しく", "Correct"],
  ["まさる", "勝る", "Win"],
  ["まし", "〜増し", "~ More"],
  ["まじえる", "交える", "Intersect"],
  ["ました", "真下", "Directly below"],
  ["まして", "況して", "Moreover"],
  ["まじわる", "交わる", "Intersect"],
  ["ますい", "麻酔", "anesthesia"],
  ["マスコミ", "mass communication", "Mass communication"],
  ["また", "股", "crotch"],
  ["またがる", "跨る「馬に〜」", "To ride a horse"],
  ["まちあわせ", "待ち合わせ", "Meeting"],
  ["まちどおしい", "待ち遠しい", "Can&#39;t wait"],
  ["まちのぞむ", "待ち望む", "Looking forward to"],
  ["まちまち", "区々", "Wards"],
  ["まつ", "末", "End"],
  ["まっき", "末期", "Terminal"],
  ["マッサージ", "massage", "Massage"],
  ["まっぷたつ", "真っ二つ", "Split in two"],
  ["まと", "的", "Target"],
  ["まとまり", "纏り", "Wrapping"],
  ["まとめ", "纏め", "Summary"],
  ["まぬがれる", "免れる", "be spared"],
  ["まねき", "招き", "Invitation"],
  ["まばたき", "瞬き", "Blink"],
  ["まひ", "麻痺", "paralysis"],
  ["まみれ", "〜まみれ", "~ Covered"],
  ["まゆ", "眉", "eyebrow"],
  ["まり", "鞠", "ball"],
  ["まるごと", "丸ごと", "Whole"],
  ["まるっきり", "丸っきり", "Completely"],
  ["まるまる", "丸々", "Whole"],
  ["まるめる", "丸める", "Rounding"],
  ["まんげつ", "満月", "full moon"],
  ["まんじょう", "満場", "Full house"],
  ["まんせい", "慢性", "Chronic"],
  ["まんまえ", "真前", "Right in front"],
  ["まんまるい,真丸い、真ん円い", "Perfectly round", " perfectly circular"],
  ["み", "〜味", "~taste"],
  ["みあい", "見合い", "Matchmaking"],
  ["みあわせる", "見合わせる", "Postpone"],
  ["みおとす", "見落す", "Overlook"],
  ["みかい", "未開", "Uncivilized"],
  ["みかく", "味覚", "taste"],
  ["みかける", "見掛ける", "see"],
  ["みき", "幹", "stem"],
  ["みぐるしい", "見苦しい", "unsightly"],
  ["みこみ", "見込み", "Prospects"],
  ["みこん", "未婚", "Unmarried"],
  ["みじゅく", "未熟", "immature"],
  ["みじん", "微塵", "Dust"],
  ["ミス", "Miss", "Miss"],
  ["みずけ", "水気", "moisture"],
  ["ミスプリント", "misprint", "misprint"],
  ["みすぼらしい", "見窄らしい", "Pathetic"],
  ["ミセス", "Mrs.", "Mrs."],
  ["みせびらかす", "見せびらかす", "show off"],
  ["みせもの", "見せ物", "Show"],
  ["みぞ", "溝", "groove"],
  ["みたす", "満たす", "satisfy"],
  ["みだれ", "乱れ", "Disturbance"],
  ["みだれる", "乱れる", "Disturbed"],
  ["みち", "未知", "Unknown"],
  ["みぢか", "身近", "Close to home"],
  ["みちばた", "道端", "roadside"],
  ["みちびく", "導く", "Lead"],
  ["みっしゅう", "密集", "Crowded"],
  ["みっせつ", "密接", "Close"],
  ["みつど", "密度", "density"],
  ["みつもり", "見積もり", "estimate"],
  ["みてい", "未定", "to be decided"],
  ["みとおし", "見通し", "Outlook"],
  ["みなす", "見做す", "consider"],
  ["みなもと", "源", "source"],
  ["みならう", "見習う", "Learn from"],
  ["みなり", "身形", "Appearance"],
  ["みね", "峰", "Peak"],
  ["みのうえ", "身の上", "Background"],
  ["みのがす", "見逃す", "miss"],
  ["みのまわり", "身の回り", "Personal belongings"],
  ["みはからう", "見計らう", "Seek out"],
  ["みはらし", "見晴らし", "view"],
  ["みぶり", "身振り", "gesture"],
  ["みゃく", "脈", "pulse"],
  ["ミュージック", "music", "Music"],
  ["みれん", "未練", "Regret"],
  ["みわたす", "見渡す", "Look Around"],
  ["みんしゅく", "民宿", "Guest House"],
  ["みんぞく", "民俗", "Folklore"],
  ["みんぞく", "民族", "Ethnicity"],
  ["むいみ", "無意味", "Pointless"],
  ["ムード", "mood", "mood"],
  ["むくち", "無口", "Silent"],
  ["むこ", "婿", "Son-in-law"],
  ["むこう", "無効", "invalid"],
  ["むごん", "無言", "Silence"],
  ["むじゃき", "無邪気", "Innocence"],
  ["むしる", "毟る", "pluck"],
  ["むすび", "結", "Conclusion"],
  ["むすびつき", "結び付き", "Connection"],
  ["むすびつく", "結び付く", "Connect"],
  ["むすびつける", "結び付ける", "Connect"],
  ["むせん", "無線", "wireless"],
  ["むだづかい", "無駄遣い", "Wasteful"],
  ["むだん", "無断", "Without permission"],
  ["むち", "無知", "ignorance"],
  ["むちゃ", "無茶", "Reckless"],
  ["むちゃくちゃ", "無茶苦茶", "Nonsense"],
  ["むなしい,空しい、虚しい", "Empty", " hollow"],
  ["むねん", "無念", "Regrettable"],
  ["むのう", "無能", "Incompetent"],
  ["むやみに", "無闇", "In the dark"],
  ["むよう", "無用", "useless"],
  ["むら", "斑", "Spots"],
  ["むらがる", "群がる", "Swarm"],
  ["むろん", "無論", "Of course"],
  ["めいさん", "名産", "Specialty"],
  ["めいしょう", "名称", "name"],
  ["めいちゅう", "命中", "hit"],
  ["めいはく", "明白", "Obvious"],
  ["めいぼ", "名簿", "Roster"],
  ["めいよ", "名誉", "honor"],
  ["めいりょう", "明瞭", "clarity"],
  ["めいろう", "明朗", "Bright"],
  ["メーカー", "maker", "maker"],
  ["めかた", "目方", "weight"],
  ["めぐみ", "恵み", "Grace"],
  ["めぐむ", "恵む", "Bless"],
  ["めくる", "捲る", "Turn over"],
  ["めざましい", "目覚ましい", "remarkable"],
  ["めざめる", "目覚める", "wake up"],
  ["めす", "召す", "Call"],
  ["めす", "雌", "female"],
  ["めつき", "目付き", "Eyes"],
  ["メッセージ", "message", "message"],
  ["めつぼう", "滅亡", "Destruction"],
  ["メディア", "media", "Media"],
  ["めど", "目途", "Target"],
  ["めもり", "目盛り", "scale"],
  ["メロディー", "melody", "melody"],
  ["めんかい", "面会", "Meeting"],
  ["めんじょ", "免除", "Exemption"],
  ["めんする", "面する", "face"],
  ["めんぼく・めんもく", "面目", "Honor"],
  ["も", "喪", "mourning"],
  ["もう", "〜網", "~network"],
  ["もうける", "設ける", "establish"],
  ["もうしいれる", "申し入れる", "Apply"],
  ["もうしこみ", "申し込み", "application"],
  ["もうしで", "申し出", "Offer"],
  ["もうしでる", "申し出る", "Offer"],
  ["もうしぶん", "申し分", "Sorry"],
  ["もうてん", "盲点", "blind spot"],
  ["もうれつ", "猛烈", "Fierce"],
  ["モーテル", "motel", "Motel"],
  ["もがく", "踠く", "Struggle"],
  ["もくろく", "目録", "Catalog"],
  ["もくろみ", "目論見", "Intent"],
  ["もけい", "模型", "model"],
  ["もさく", "模索", "Explore"],
  ["もしかして", "若しかして", "Young"],
  ["もしくは", "若しくは", "Or"],
  ["もたらす", "齎す", "bring"],
  ["もちきり", "持ち切り", "Hold on"],
  ["もっか", "目下", "Currently"],
  ["もって", "以って", "Thus"],
  ["もっぱら", "専ら", "solely"],
  ["もてなす", "持て成す", "entertain"],
  ["もてる", "持てる", "Can be held"],
  ["モニター", "monitor", "Monitor"],
  ["もの", "〜物", "~thing"],
  ["ものずき", "物好き", "Strange"],
  ["ものたりない", "物足りない", "Not Enough"],
  ["もはや", "最早", "Earliest"],
  ["もはん", "模範", "Example"],
  ["もほう", "模倣", "Imitation"],
  ["もめる", "揉める", "Argue"],
  ["もよおす", "催す", "hold"],
  ["もらす", "漏らす", "leak"],
  ["もりあがる", "盛り上がる", "Get excited"],
  ["もる", "漏る", "leak"],
  ["もれる", "漏れる", "Leak"],
  ["もろい", "もろに", "To the point"],
  ["もろい", "脆い", "brittle"],
  ["や", "矢", "arrow"],
  ["やがい", "野外", "Outdoors"],
  ["やく", "〜薬", "~medicine"],
  ["やぐ", "夜具", "Bedding"],
  ["やくしょく", "役職", "post"],
  ["やくば", "役場", "Town hall"],
  ["やけに", "自棄に", "In desperation"],
  ["やしき", "屋敷", "Mansion"],
  ["やしなう", "養う", "Nurture"],
  ["やしん", "野心", "ambition"],
  ["やすっぽい", "安っぽい", "Cheap"],
  ["やすめる", "休める", "rest"],
  ["やせい", "野生", "wild"],
  ["やたら,矢鱈に・矢鱈と", "Randomly", " randomly"],
  ["やつ", "奴", "guy"],
  ["やとう", "野党", "Opposition"],
  ["やみ", "闇", "darkness"],
  ["やむ", "病む", "get sick"],
  ["ややこしい", "ややこしい", "Confusing"],
  ["やりとおす", "遣り通す", "Carry out"],
  ["やりとげる", "遣り遂げる", "accomplish"],
  ["やわらげる", "和らげる", "relieve"],
  ["ヤング", "young", "young"],
  ["ゆ", "〜油", "~oil"],
  ["ゆう", "優", "Excellent"],
  ["ゆうい", "優位", "Advantage"],
  ["ゆううつ", "憂鬱", "melancholy"],
  ["ゆうえき", "有益", "beneficial"],
  ["ゆうえつ", "優越", "Superiority"],
  ["ゆうかん", "勇敢", "Brave"],
  ["ゆうき", "有機", "Organic"],
  ["ゆうぐれ", "夕暮れ", "evening"],
  ["ゆうし", "融資", "Financing"],
  ["ゆうずう", "融通", "Flexible"],
  ["ゆうする", "有する", "Have"],
  ["ゆうせい", "優勢", "Advantage"],
  ["ゆうせん", "優先", "priority"],
  ["ゆうどう", "誘導", "Induction"],
  ["ゆうび", "優美", "Grace"],
  ["ゆうぼう", "有望", "promising"],
  ["ゆうぼく", "遊牧", "Nomadism"],
  ["ゆうやけ", "夕焼け", "sunset"],
  ["ゆうりょく", "有力", "Influential"],
  ["ゆうれい", "幽霊", "ghost"],
  ["ゆうわく", "誘惑", "Seduction"],
  ["ゆえ（に）", "故（に）", "Therefore"],
  ["ゆがむ", "歪む", "distort"],
  ["ゆさぶる", "揺さぶる", "Shake"],
  ["ゆすぐ", "濯ぐ", "rinse"],
  ["ゆとり", "ゆとり", "Leisure"],
  ["ユニーク", "unique", "unique"],
  ["ユニフォーム", "uniform", "uniform"],
  ["ゆびさす", "指差す", "Pointing"],
  ["ゆみ", "弓", "bow"],
  ["ゆらぐ", "揺らぐ", "Waver"],
  ["ゆるむ", "緩む", "Loosen"],
  ["ゆるめる", "緩める", "loosen"],
  ["ゆるやか", "緩やか", "Gentle"],
  ["よ", "世", "world"],
  ["よい", "良い", "good"],
  ["よう", "洋〜", "Western"],
  ["よういん", "要因", "factor"],
  ["ようえき", "溶液", "solution"],
  ["ようけん", "用件", "Subject"],
  ["ようご", "養護", "Nursing care"],
  ["ようしき", "様式", "Style"],
  ["ようする", "要する", "Required"],
  ["ようせい", "要請", "request"],
  ["ようせい", "養成", "training"],
  ["ようそう", "様相", "phase"],
  ["ようひん", "用品", "Supplies"],
  ["ようふう", "洋風", "Western style"],
  ["ようほう", "用法", "Usage"],
  ["ようぼう", "要望", "request"],
  ["よか", "余暇", "Leisure"],
  ["よかん", "予感", "Premonition"],
  ["よきょう", "余興", "entertainment"],
  ["よきん", "預金", "deposit"],
  ["よく", "欲", "Greed"],
  ["よくあつ", "抑圧", "suppression"],
  ["よくしつ", "浴室", "bathroom"],
  ["よくじつ", "翌日", "the next day"],
  ["よくせい", "抑制", "Suppression"],
  ["よくふかい", "欲深い", "Greedy"],
  ["よくぼう", "欲望", "desire"],
  ["よける", "避ける", "avoid"],
  ["よげん", "予言", "Prophecy"],
  ["よこづな", "横綱", "Yokozuna"],
  ["よごれ", "汚れ", "Dirt"],
  ["よし", "よし", "Okay."],
  ["よし", "良し", "Good"],
  ["よしあし", "善し悪し", "good or bad"],
  ["よそう", "予想", "prediction"],
  ["よそみ", "余所見", "looking away"],
  ["よち", "余地", "room"],
  ["よって", "因って、由って「拠りどころ」", "a place of refuge"],
  ["よとう", "与党", "Ruling party"],
  ["よびとめる", "呼び止める", "Call someone down"],
  ["よふかし", "夜更かし", "Staying up late"],
  ["よふけ", "夜更け", "Late Night"],
  ["よほど", "余程", "very"],
  ["よみあげる", "読み上げる", "Read aloud"],
  ["よみがえる", "蘇る", "Revive"],
  ["より", "〜寄り", "~ Close"],
  ["よりかかる", "寄り掛かる", "Lean on"],
  ["よろん", "輿論、世論", "public opinion"],
  ["よわまる", "弱まる", "Weakening"],
  ["よわめる", "弱める", "Weaken"],
  ["よわる", "弱る", "Weakened"],
  ["らいじょう", "来場", "Visit"],
  ["ライス", "rice", "rice"],
  ["ライバル", "rival", "rival"],
  ["らくのう", "酪農", "dairy"],
  ["らっか", "落下", "Fall"],
  ["らっかん", "楽観", "Optimism"],
  ["ラベル", "label", "label"],
  ["ランプ", "lamp", "lamp"],
  ["らんよう", "濫用", "abuse"],
  ["リード", "lead", "lead"],
  ["りくつ", "理屈", "reason"],
  ["りし", "利子", "interest"],
  ["りじゅん", "利潤", "profit"],
  ["りせい", "理性", "reason"],
  ["りそく", "利息", "interest"],
  ["りったい", "立体", "Three-dimensional"],
  ["りっぽう", "立方", "Cube"],
  ["りっぽう", "立法", "legislation"],
  ["りてん", "利点", "advantage"],
  ["りゃくご", "略語", "Abbreviations"],
  ["りゃくだつ", "略奪", "Plunder"],
  ["りゅうつう", "流通", "distribution"],
  ["りょういき", "領域", "region"],
  ["りょうかい", "了解", "roger that"],
  ["りょうかい", "領海", "Territorial waters"],
  ["りょうきょく", "両極", "Both poles"],
  ["りょうこう", "良好", "Good"],
  ["りょうしき", "良識", "Common sense"],
  ["りょうしつ", "良質", "Good quality"],
  ["りょうしょう", "了承", "understand"],
  ["りょうしん", "良心", "conscience"],
  ["りょうち", "領地", "territory"],
  ["りょうど", "領土", "territory"],
  ["りょうりつ", "両立", "Balance"],
  ["りょかく", "旅客", "passenger"],
  ["りょけん", "旅券", "passport"],
  ["りれき", "履歴", "history"],
  ["りろん", "理論", "theory"],
  ["りんぎょう", "林業", "forestry"],
  ["るい", "類", "Category"],
  ["るいじ", "類似", "Similar"],
  ["るいすい", "類推", "analogy"],
  ["ルーズ", "loose", "loose"],
  ["ルール", "rule", "rule"],
  ["れいこく", "冷酷", "ruthless"],
  ["れいぞう", "冷蔵", "Refrigerated"],
  ["れいたん", "冷淡", "Cold"],
  ["レース", "race/lace", "race/lace"],
  ["レギュラー", "regular", "Regular"],
  ["レッスン", "lesson", "lesson"],
  ["レディー", "lady", "Lady"],
  ["レバー", "「取手」", "lever「Handle」"],
  ["れんあい", "恋愛", "love affair"],
  ["れんきゅう", "連休", "Long weekend"],
  ["レンジ", "range", "range"],
  ["れんじつ", "連日", "Every day"],
  ["れんたい", "連帯", "solidarity"],
  ["レンタカー", "rent-a-car", "rent-a-car"],
  ["れんちゅう", "連中", "Guys"],
  ["レントゲン", "Röntgen", "Rontgen"],
  ["れんぽう", "連邦", "Federal"],
  ["れんめい", "連盟", "Federation"],
  ["ろうすい", "老衰", "Senility"],
  ["ろうどく", "朗読", "Reading"],
  ["ろうひ", "浪費", "waste"],
  ["ろうりょく", "労力", "effort"],
  ["ロープ", "rope", "rope"],
  ["ロープウエイ", "ropeway", "ropeway"],
  ["ろく", "碌な・碌に", " very good"],
  ["ろこつ", "露骨", "Blatant"],
  ["ロマンチック／ロマンティック", "romantic", "romantic"],
  ["ろんぎ", "論議", "Discussion"],
  ["ろんり", "論理", "logic"],
  ["わかもの", "若者", "Young people"],
  ["わく", "枠", "frame"],
  ["わくせい", "惑星", "planet"],
  ["わざ", "技", "Technique"],
  ["わざわざ", "態々", "On purpose"],
  ["わずらわしい", "煩わしい", "troublesome"],
  ["わたりどり", "渡り鳥", "Migratory birds"],
  ["ワット", "watt", "watt"],
  ["わび", "詫び", "apology"],
  ["わふう", "和風", "Japanese style"],
  ["わぶん", "和文", "Japanese sentence"],
  ["わら", "藁", "straw"],
  ["わり", "〜割", "~%"],
  ["わりあて", "割当", "allocation"],
  ["わりこむ", "割込む", "interrupt"],
  ["わるもの", "悪者", "Bad guy"],
  ["われ", "我", "I"],
];
